export default {
    "en": {
        "": "",
        "#": "#",
        "# Pieces": "# Pieces",
        "(and {count} more error)": "(and {count} more error)",
        "(and {count} more errors)": "(and {count} more error)|(and {count} more errors)|(and {count} more errors)",
        "+": "+",
        "-": "-",
        "+1": "+1",
        "2.40 meters": "2.40 meters",
        "2.70 meters": "2.70 meters",
        "3 meters": "3 meters",
        "404 - Page not found": "404 - Page not found",
        "6-Digit code": "6-Digit code",
        "{activity} the {entity}": "{activity} the {entity}",
        "{airWaybillNumber} was assigned to {expectedHandler} but received NOA from {actualHandler}": "{airWaybillNumber} was assigned to {expectedHandler} but received NOA from {actualHandler}",
        "{Attribute} {lang}": "{Attribute} {lang}",
        "{Attribute} is invalid": "{Attribute} is invalid",
        "{count} characters": "{count} characters",
        "{date} - {pieces} pieces": "{date} - {pieces} pieces",
        "{day} from": "{day} from",
        "{model} {action}": "{model} {action}",
        "{model} {number}": "{model} {number}",
        "{number} departed from {location} at {time} but seems to has stopped in transit. Please check tracking.": "{number} departed from {location} at {time} but seems to has stopped in transit. Please check tracking.",
        "{number} loading times have been declined": "{number} loading times have been declined",
        "{number} was planned {hours} later than original requested time. Please check.": "{number} was planned {hours} later than original requested time. Please check.",
        "{number} would start loading at {time} at {location}, but we noticed that it has not yet left. Can you indicate the implications of arrival time?": "{number} would start loading at {time} at {location}, but we noticed that it has not yet left. Can you indicate the implications of arrival time?",
        "{package} on trip {trip} with {pieces} pc": "{package} on trip {trip} with {pieces} pc",
        "{package} on with {pieces} pc": "{package} on with {pieces} pc",
        "{type} - {uldCode} - {tripNumber}": "{type} - {uldCode} - {tripNumber}",
        "{uld_code} : {air_waybill_number}": "{uld_code} : {air_waybill_number}",
        "{uld_type} {uld_code} {pieces_of_air_waybill} / {pieces_of_package}": "{uld_type} {uld_code} {pieces_of_air_waybill} / {pieces_of_package}",
        "{uldCode} is already assigned to {airWaybillNumber} by {userName} at {time}. Please reassign or delete pmc first": "{uldCode} is already assigned to {airWaybillNumber} by {userName} at {time}. Please reassign or delete pmc first",
        "{uldCode} is already assigned to {airWaybillNumber} by {userName} at {time}. Please reassign or delete pmc first.": "{uldCode} is already assigned to {airWaybillNumber} by {userName} at {time}. Please reassign or delete pmc first.",
        "{userName} attempted to assign an occupied uld code {uldCode}": "{userName} attempted to assign an occupied uld code {uldCode}",
        "{userName} attempted to assign an occupied uld code {uldCode}. This code was already assigned  to {airWaybillNumber} by {otherUserName} at {time}.": "{userName} attempted to assign an occupied uld code {uldCode}. This code was already assigned  to {airWaybillNumber} by {otherUserName} at {time}.",
        "{userName} of {scopeName} has deprioritized following air waybills:": "{userName} of {scopeName} has deprioritized following air waybills:",
        "{userName} of {scopeName} has prioritized following air waybills:": "{userName} of {scopeName} has prioritized following air waybills:",
        "{variant} image": "{variant} image",
        "<strong>Important update:</strong><br>This is an update on the loading list previously sent to you, please discard version": "<strong>Important update:</strong><br>This is an update on the loading list previously sent to you, please discard version",
        "<strong>Minimum Height:</strong> {minimumHeight}": "<strong>Minimum Height:</strong> {minimumHeight}",
        "<strong>Planning Instructions:</strong> {planningInstructions}": "<strong>Planning Instructions:</strong> {planningInstructions}",
        "<strong>Trailer Type:</strong> {trailerType}": "<strong>Trailer Type:</strong> {trailerType}",
        "A comma separated list of mail addresses.": "A comma separated list of mail addresses.",
        "A decryption key is required.": "A decryption key is required.",
        "A first requested time between {firstDatetime} and {lastDatetime}": "A first requested time between {firstDatetime} and {lastDatetime}",
        "A first requested time of {datetime}": "A first requested time of {datetime}",
        "A message will be send to this/these handlers notifying them to discard this reference.": "A message will be send to this/these handlers notifying them to discard this reference.",
        "A new incident report has been created.": "A new incident report has been created.",
        "A new verification link has been sent to the email address you provided in your profile settings.": "A new verification link has been sent to the email address you provided in your profile settings.",
        "A new verification link has been sent to your email address.": "A new verification link has been sent to your email address.",
        "A package from air waybill {numbers} was planned on trip {tripNumber} for delivery to {oldWarehouse}, but {name} just changed the warehouse to {newWarehouse}.": "A package from air waybill {numbers} was planned on trip {tripNumber} for delivery to {oldWarehouse}, but {name} just changed the warehouse to {newWarehouse}.",
        "A package from air waybill {numbers} was planned on trip {tripNumber} for delivery to {oldWarehouse}, but the warehouse changed to {newWarehouse}.": "A package from air waybill {numbers} was planned on trip {tripNumber} for delivery to {oldWarehouse}, but the warehouse changed to {newWarehouse}.",
        "A piece": "A piece",
        "A Timeout Occurred": "A Timeout Occurred",
        "A total of {total} pc for this AWB are planned to be delivered in MST, but so far we’ve only scanned {scanned} pc.": "A total of {total} pc for this AWB are planned to be delivered in MST, but so far we’ve only scanned {scanned} pc.",
        "Ability": "Ability",
        "Ability model": "Ability model",
        "About us": "About us",
        "Accept": "Accept",
        "Accept Invitation": "Accept Invitation",
        "Accepted": "Accepted",
        "According to the NOA, the air waybill arrives on uld {uldCode}, no such package is planned.": "According to the NOA, the air waybill arrives on uld {uldCode}, no such package is planned.",
        "According to Track & Trace, the cargo didn’t departed yet.": "According to Track & Trace, the cargo didn’t departed yet.",
        "Account": "Account",
        "Action": "Action",
        "Actions": "Actions",
        "Activated": "activated",
        "activated": "activated",
        "Active": "Active",
        "Add": "Add",
        "Add {model}": "Add {model}",
        "Add {name}": "Add {name}",
        "Add a new team member to your team, allowing them to collaborate with you.": "Add a new team member to your team, allowing them to collaborate with you.",
        "Add additional security to your account using two factor authentication.": "Add additional security to your account using two factor authentication.",
        "Add air waybill": "Add air waybill",
        "Add as a comma separated list of mail addresses.": "Add as a comma separated list of mail addresses.",
        "Add Comment": "Add Comment",
        "Add comment": "Add comment",
        "Add line": "Add line",
        "Add member": "Add member",
        "Add menu item": "Add menu item",
        "Add message": "Add message",
        "add one": "add one",
        "Add page block": "Add page block",
        "Add pallet": "Add pallet",
        "Add piece": "Add piece",
        "Add Stop #": "Add Stop #",
        "Add Team Member": "Add Team Member",
        "Add to cart": "Add to cart",
        "Add to favorites": "Add to favorites",
        "Add to shopping cart": "Add to shopping cart",
        "Added.": "Added.",
        "Address": "Address",
        "address": "address",
        "Addressable": "Addressable",
        "Addresses": "Addresses",
        "Admin": "Admin",
        "Administrator": "Administrator",
        "Administrator users can perform any action.": "Administrator users can perform any action.",
        "Agree": "Agree",
        "Air Waybill": "Air Waybill",
        "Air waybill": "Air waybill",
        "Air waybill {airWaybillNumber} already created this run": "Air waybill {airWaybillNumber} already created this run",
        "Air waybill {airWaybillNumber} already exists": "Air waybill {airWaybillNumber} already exists",
        "Air waybill {airWaybillNumber} created": "Air waybill {airWaybillNumber} created",
        "Air waybill {number} has no trip linked while we received a NOA at {time}": "Air waybill {number} has no trip linked while we received a NOA at {time}",
        "Air waybill {number} is planned for {time} on trip {tripNumber}, we received a NOA at {noaTime}": "Air waybill {number} is planned for {time} on trip {tripNumber}, we received a NOA at {noaTime}",
        "Air waybill {number} is planned for {time}, we just received a NOA": "Air waybill {number} is planned for {time}, we just received a NOA",
        "Air waybill {number} of client {client} has no trip linked while we received a NOA at {time}": "Air waybill {number} of client {client} has no trip linked while we received a NOA at {time}",
        "Air Waybill {number} pieces": "Air Waybill {number} pieces",
        "Air waybill already added": "Air waybill already added",
        "Air Waybill number": "Air Waybill number",
        "Air waybill number": "Air waybill number",
        "Air Waybill pieces": "Air Waybill pieces",
        "Air waybill pieces": "Air waybill pieces",
        "Air Waybill pieces on {uldCode}": "Air Waybill pieces on {uldCode}",
        "Air Waybill pieces on loose": "Air Waybill pieces on loose",
        "Air Waybill Warehouse Overview": "Air Waybill Warehouse Overview",
        "Air Waybills": "Air Waybills",
        "Air waybills": "Air waybills",
        "Air waybills have been deprioritized": "Air waybills have been deprioritized",
        "Air waybills have been prioritized": "Air waybills have been prioritized",
        "Air waybills in package": "Air waybills in package",
        "Air Waybills on package": "Air Waybills on package",
        "Air Waybills on package {uldCode}": "Air Waybills on package {uldCode}",
        "Airport code": "Airport code",
        "Aliases": "Aliases",
        "All": "All",
        "All {models}": "All {models}",
        "All blogs": "All blogs",
        "All clients": "All clients",
        "All handlers": "All handlers",
        "All notification": "All notification",
        "All of the people that are part of this team.": "All of the people that are part of this team.",
        "All packages have a trip which has been requested": "All packages have a trip which has been requested",
        "All pieces have been delivered, according to RCF messages": "All pieces have been delivered, according to RCF messages",
        "All pieces have been delivered, according to warehouse RCF messages": "All pieces have been delivered, according to warehouse RCF messages",
        "All pieces have entered the last mile, according to DLV messages": "All pieces have entered the last mile, according to DLV messages",
        "All pieces have entered the last mile, according to warehouse DLV messages": "All pieces have entered the last mile, according to warehouse DLV messages",
        "All Products": "All Products",
        "All products": "All products",
        "All questions": "All questions",
        "All rights reserved.": "All rights reserved.",
        "All statuses": "All statuses",
        "All warehouses": "All warehouses",
        "Allowed arrival deviation": "Allowed arrival deviation",
        "Allowed mail addresses": "Allowed mail addresses",
        "Allowed mail addresses for Noa Mail": "Allowed mail addresses for Noa Mail",
        "Allowed requested arrival deviation": "Allowed requested arrival deviation",
        "Already registered?": "Already registered?",
        "Already Reported": "Already Reported",
        "Alt text": "Alt text",
        "Always request 3 meter height": "Always request 3 meter height",
        "Amount": "Amount",
        "Amount of products": "Amount of products",
        "An email with the subject: {subject} from {sender} was received.": "An email with the subject: {subject} from {sender} was received.",
        "An error occurred": "An error occurred",
        "An error occurred in the application:": "An error occurred in the application:",
        "Answer": "Answer",
        "API Token": "API Token",
        "API Token Permissions": "API Token Permissions",
        "API Tokens": "API Tokens",
        "API tokens allow third-party services to authenticate with our application on your behalf.": "API tokens allow third-party services to authenticate with our application on your behalf.",
        "Apply discount code": "Apply discount code",
        "Approve": "Approve",
        "Archive": "Archive",
        "Are you sure you want retract the membership of {model}?": "Are you sure you want retract the membership of {model}?",
        "Are you sure you want to delete {model}?": "Are you sure you want to delete {model}?",
        "Are you sure you want to delete this {model}?": "Are you sure you want to delete this {model}?",
        "Are you sure you want to delete this air waybill package?": "Are you sure you want to delete this air waybill package?",
        "Are you sure you want to delete this file?": "Are you sure you want to delete this file?",
        "Are you sure you want to delete this network?": "Are you sure you want to delete this network?",
        "Are you sure you want to delete this package?": "Are you sure you want to delete this package?",
        "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.": "Are you sure you want to delete this team? Once a team is deleted, all of its resources and data will be permanently deleted.",
        "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.": "Are you sure you want to delete your account? Once your account is deleted, all of its resources and data will be permanently deleted. Please enter your password to confirm you would like to permanently delete your account.",
        "Are you sure you would like to delete this API token?": "Are you sure you would like to delete this API token?",
        "Are you sure you would like to leave this team?": "Are you sure you would like to leave this team?",
        "Are you sure you would like to remove this person from the team?": "Are you sure you would like to remove this person from the team?",
        "ARR Received": "ARR Received",
        "Arrival at": "Arrival at",
        "Arrival truck": "Arrival truck",
        "Ascension day": "Ascension day",
        "Asfsdfge overview": "Asfsdfge overview",
        "Assign": "Assign",
        "Assign fyco": "Assign fyco",
        "Assign fyco parcels": "Assign fyco parcels",
        "Assign user": "Assign user",
        "Assigned": "Assigned",
        "Assignment Status": "Assignment Status",
        "Associate": "Associate",
        "ATA": "ATA",
        "Attach": "Attach",
        "Attach Air Waybill": "Attach Air Waybill",
        "Attach package": "Attach package",
        "Attached is the data export.": "Attached is the data export.",
        "Attached is the QLS data export for {date}.": "Attached is the QLS data export for {date}.",
        "Attached is the QLS data export from {from} to {to}.": "Attached is the QLS data export from {from} to {to}.",
        "Attached you can find the loading list, or click the button to download it.": "Attached you can find the loading list, or click the button to download it.",
        "Attachments": "Attachments",
        "auth.throttle_generic": "auth.throttle_generic",
        "Author": "Author",
        "Auto": "Auto",
        "Automation": "Automation",
        "AWB": "AWB",
        "AWB {airWaybill} has the following packages:": "AWB {airWaybill} has the following packages:",
        "AWB no.": "AWB no.",
        "AWB status": "AWB status",
        "AWD Received": "AWD Received",
        "AWR Received": "AWR Received",
        "Back": "Back",
        "Back and mark as done": "Back and mark as done",
        "Back and release": "Back and release",
        "Back to home": "Back to home",
        "Back to the shopping cart": "Back to the shopping cart",
        "Backup codes": "Backup codes",
        "Bad Gateway": "Bad Gateway",
        "Bad Request": "Bad Request",
        "Bags": "Bags",
        "Bandwidth Limit Exceeded": "Bandwidth Limit Exceeded",
        "Banner": "Banner",
        "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.": "Before continuing, could you verify your email address by clicking on the link we just emailed to you? If you didn't receive the email, we will gladly send you another.",
        "Belgium": "Belgium",
        "Billing address": "Billing address",
        "Blog": "Blog",
        "Blog Settings": "Blog Settings",
        "Blogs": "Blogs",
        "Blue Button": "Blue Button",
        "Boltrics sent a zero quantity for air waybill {airWaybillNumber}": "Boltrics sent a zero quantity for air waybill {airWaybillNumber}",
        "Boltrics Zero Quantity {airWaybillNumber}": "Boltrics Zero Quantity {airWaybillNumber}",
        "Both packages and air waybill pieces are known but not linked": "Both packages and air waybill pieces are known but not linked",
        "Browse": "Browse",
        "Browser Sessions": "Browser Sessions",
        "BTW number": "BTW number",
        "Bullet point": "Bullet point",
        "Bullet points": "Bullet points",
        "Buttons": "Buttons",
        "By subscribing you agree to receive our newsletter.": "By subscribing you agree to receive our newsletter.",
        "Calculated loading time": "Calculated loading time",
        "Calculated unloading time": "Calculated unloading time",
        "Can you load on other times, or do you have any other questions/suggestions?": "Can you load on other times, or do you have any other questions/suggestions?",
        "Cancel": "Cancel",
        "Cancellation LoadingList {reference}": "Cancellation LoadingList {reference}",
        "Cargo": "Cargo",
        "Categories": "Categories",
        "Category": "Category",
        "Category Settings": "Category Settings",
        "Category slider": "Category slider",
        "Challenge tracker group found for air waybill with non-challenge handler": "Challenge tracker group found for air waybill with non-challenge handler",
        "Change": "Change",
        "Change password": "Change password",
        "Changes": "Changes",
        "Changing the status will not notify the transporter, this needs to be done manually.": "Changing the status will not notify the transporter, this needs to be done manually.",
        "Check": "Check",
        "Checklist": "Checklist",
        "Checkout": "Checkout",
        "Checkout page": "Checkout page",
        "Choose": "Choose",
        "Choose {name}": "Choose {name}",
        "Choose File": "Choose File",
        "Choose Image": "Choose Image",
        "Choose link type": "Choose link type",
        "City": "City",
        "Claim": "Claim",
        "Claim {model}": "Claim {model}",
        "Claim trip {number}": "Claim trip {number}",
        "Click here to re-send the verification email.": "Click here to re-send the verification email.",
        "Click here to upload a file": "Click here to upload a file",
        "Click the button below to reset your pincode.": "Click the button below to reset your pincode.",
        "Click to attach this air waybill instead": "Click to attach this air waybill instead",
        "Click to copy": "Click to copy",
        "Client": "Client",
        "client": "Client",
        "Client Closed Request": "Client Closed Request",
        "Clients": "Clients",
        "Close": "Close",
        "Close conversation": "Close conversation",
        "Closed": "Closed",
        "closed": "closed",
        "CMR\\'s": "CMR\\'s",
        "CMR\\'s / POD\\'s": "CMR\\'s / POD\\'s",
        "CMRs / PODs": "CMRs / PODs",
        "CoC": "CoC",
        "Code": "Code",
        "code": "code",
        "Codes": "Codes",
        "Collapse": "Collapse",
        "Collapse All": "Collapse All",
        "Combine Pallet": "Combine Pallet",
        "Combine pallets": "Combine pallets",
        "Combined": "Combined",
        "Combined Pallets": "Combined Pallets",
        "Combines": "Combines",
        "Comma separated list of codes": "Comma separated list of codes",
        "Comma separated list of NOA fragments that should be present in the NOA email. Is case insensitive and whitespaces will be collapsed.": "Comma separated list of NOA fragments that should be present in the NOA email. Is case insensitive and whitespaces will be collapsed.",
        "Comment": "Comment",
        "Comments": "Comments",
        "Commerce settings": "Commerce settings",
        "Company details": "Company details",
        "Company name": "Company name",
        "Complete": "Complete",
        "Concept in only visible to logged in users, draft is not visible to anyone": "Concept in only visible to logged in users, draft is not visible to anyone",
        "Conditional Page link": "Conditional Page link",
        "Confirm": "Confirm",
        "Confirm Password": "Confirm Password",
        "Confirm password": "Confirm password",
        "Conflict": "Conflict",
        "Connect": "Connect",
        "Connection Closed Without Response": "Connection Closed Without Response",
        "Connection Timed Out": "Connection Timed Out",
        "Consultant": "Consultant",
        "Contact": "Contact",
        "Content": "Content",
        "Content (Collapsible)": "Content (Collapsible)",
        "Continue": "Continue",
        "Conversation": "Conversation",
        "Conversations": "Conversations",
        "Copied": "Copied",
        "Countdown free time for pickup": "Countdown free time for pickup",
        "Country": "Country",
        "Coupon": "Coupon",
        "Coupon applied": "Coupon applied",
        "Coupon can no longer be used": "Coupon can no longer be used",
        "Coupon not found": "Coupon not found",
        "Coupons": "Coupons",
        "Create": "Create",
        "Create {model}": "Create {model}",
        "Create {name}": "Create {name}",
        "Create a new team to collaborate with others on projects.": "Create a new team to collaborate with others on projects.",
        "Create Account": "Create Account",
        "Create address": "Create address",
        "Create Air Waybill": "Create Air Waybill",
        "Create an account": "Create an account",
        "Create and add configure": "Create and add configure",
        "Create API Token": "Create API Token",
        "Create client": "Create client",
        "Create cmr": "Create cmr",
        "Create follow-up": "Create follow-up",
        "Create Incident Report": "Create Incident Report",
        "Create Network": "Create Network",
        "Create new package": "Create new package",
        "Create New Team": "Create New Team",
        "Create non-rollerbed trip": "Create non-rollerbed trip",
        "Create order": "Create order",
        "Create package": "Create package",
        "Create Pallet": "Create Pallet",
        "Create Pickup": "Create Pickup",
        "Create pickup": "Create pickup",
        "Create Prioritized Air Waybill": "Create Prioritized Air Waybill",
        "Create Prioritized Air Waybills": "Create Prioritized Air Waybills",
        "Create stack": "Create stack",
        "Create stack trip": "Create stack trip",
        "Create Team": "Create Team",
        "Create transporter company": "Create transporter company",
        "Create trip": "Create trip",
        "Create warehouse": "Create warehouse",
        "created": "created",
        "Created": "Created",
        "Created at": "Created at",
        "Created at {createdAt}. Updated by {name} at {updatedAt}": "Created at {createdAt}. Updated by {name} at {updatedAt}",
        "Created.": "Created.",
        "Current packages on air waybill:": "Current packages on air waybill:",
        "Current Password": "Current Password",
        "Current password": "Current password",
        "Currently no stock": "Currently no stock",
        "Custom cleared": "Custom cleared",
        "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}": "Customers will be shown the cheapest applicable shipping rate from each group. Currently existing groups: {groups}",
        "Customs cleared": "Customs cleared",
        "Customs Cleared": "Customs Cleared",
        "Dangerously high": "Dangerously high",
        "Dangerously high explanation": "Dangerously high explanation",
        "Dangerously Low": "Dangerously Low",
        "Dangerously low explanation": "Dangerously low explanation",
        "Dashboard": "Dashboard",
        "Data Export": "Data Export",
        "Date": "Date",
        "Date from": "Date from",
        "Date of birth": "Date of birth",
        "Date to": "Date to",
        "De laatste trends": "De laatste trends",
        "Deactivate": "Deactivate",
        "Deactivate air waybill on create": "Deactivate air waybill on create",
        "Deactivated": "deactivated",
        "deactivated": "deactivated",
        "Dear {client},": "Dear {client},",
        "Dear {handler},": "Dear {handler},",
        "Dear team,": "Dear team,",
        "Decline": "Decline",
        "Decline times": "Decline times",
        "Default consultant": "Default consultant",
        "Default trip height": "Default trip height",
        "Default warehouse address": "Default warehouse address",
        "Delete": "Delete",
        "Delete {model}": "Delete {model}",
        "Delete {name}": "Delete {name}",
        "Delete Account": "Delete Account",
        "Delete Air Waybill Package": "Delete Air Waybill Package",
        "Delete API Token": "Delete API Token",
        "Delete file": "Delete file",
        "Delete network": "Delete network",
        "Delete package": "Delete package",
        "Delete Team": "Delete Team",
        "deleted": "deleted",
        "Delivered": "Delivered",
        "DEP": "DEP",
        "DEP Received": "DEP Received",
        "Departure at": "Departure at",
        "Deprioritize Air Waybill": "Deprioritize Air Waybill",
        "Deprioritized air waybill": "Deprioritized air waybill",
        "Description": "Description",
        "description": "description",
        "Desktop": "Desktop",
        "Detach": "Detach",
        "Details": "Details",
        "Deviation from requested time is: {deviation} hours": "Deviation from requested time is: {deviation} hours",
        "Disable": "Disable",
        "Disable swiper": "Disable swiper",
        "Disabling a coupon will prevent it from being used": "Disabling a coupon will prevent it from being used",
        "Discard": "Discard",
        "Discount": "Discount",
        "Discount active": "Discount active",
        "Discount code": "Discount code",
        "Distance (km)": "Distance (km)",
        "DLV pieces": "DLV pieces",
        "DLV Received": "DLV Received",
        "DLV Time": "DLV Time",
        "DLV time": "DLV time",
        "DLV time(s)": "DLV time(s)",
        "done": "done",
        "Done": "Done",
        "Done.": "Done.",
        "Don’t have access to your authenticator device ?": "Don’t have access to your authenticator device ?",
        "Double ULD Cost": "Double ULD Cost",
        "Down": "Down",
        "Download": "Download",
        "Download Air Waybill": "Download Air Waybill",
        "Download app": "Download app",
        "Download AWB": "Download AWB",
        "Download backup codes and continue.": "Download backup codes and continue.",
        "Download checklist": "Download checklist",
        "Download CMR": "Download CMR",
        "Download GN Codes Document": "Download GN Codes Document",
        "Download HS Codes Document": "Download HS Codes Document",
        "Download invoice": "Download invoice",
        "Download Loaded Excel": "Download Loaded Excel",
        "Download Loading List": "Download Loading List",
        "Download Loading List for {shipment}": "Download Loading List for {shipment}",
        "Download NOA": "Download NOA",
        "Download NOA ({dateTime})": "Download NOA ({dateTime})",
        "Download T1: {name}": "Download T1: {name}",
        "Download Truck Loading List": "Download Truck Loading List",
        "Download Truck Loading List for {shipment}": "Download Truck Loading List for {shipment}",
        "Download Unloaded Excel": "Download Unloaded Excel",
        "Driver name": "Driver name",
        "Due at": "Due at",
        "Duplicate": "Duplicate",
        "Duplicate {name}": "Duplicate {name}",
        "E-mail": "E-mail",
        "e-mail": "E-mail",
        "Earliest NOA time": "Earliest NOA time",
        "Easter monday": "Easter monday",
        "Easter sunday": "Easter sunday",
        "Edit": "Edit",
        "Edit {model}": "Edit {model}",
        "Edit {name}": "Edit {name}",
        "Edit account": "Edit account",
        "Edit Addresses": "Edit Addresses",
        "Edit air waybill": "Edit air waybill",
        "Edit Handler": "Edit Handler",
        "Edit milestones": "Edit milestones",
        "Edit package": "Edit package",
        "Edit Profile": "Edit Profile",
        "Editor": "Editor",
        "Editor users have the ability to read, create, and update.": "Editor users have the ability to read, create, and update.",
        "Email": "Email",
        "email": "email",
        "Email address": "Email address",
        "Email addresses to notify when NOA is found for unknown air waybill number": "Email addresses to notify when NOA is found for unknown air waybill number",
        "Email Password Reset Link": "Email Password Reset Link",
        "Empty Stack Cost": "Empty Stack Cost",
        "Empty ULD": "Empty ULD",
        "Enable": "Enable",
        "Enable transit documents upload": "Enable transit documents upload",
        "Encrypted environment file already exists.": "Encrypted environment file already exists.",
        "Encrypted environment file not found.": "Encrypted environment file not found.",
        "End": "End",
        "End loading time": "End loading time",
        "End time": "End time",
        "End unloading time": "End unloading time",
        "Ensure your account is using a long, random password to stay secure.": "Ensure your account is using a long, random password to stay secure.",
        "Enter mail addresses": "Enter mail addresses",
        "Enter notification text": "Enter notification text",
        "Enter your comment here": "Enter your comment here",
        "Enter your e-mail and we send you a reset link": "Enter your e-mail and we send you a reset link",
        "Environment file already exists.": "Environment file already exists.",
        "Environment file not found.": "Environment file not found.",
        "errors": "errors",
        "Estimated loading time": "Estimated loading time",
        "ETA": "ETA",
        "ETA truck": "ETA truck",
        "ETA unloading": "ETA unloading",
        "ETD": "ETD",
        "Exact": "Exact",
        "Excerpt": "Excerpt",
        "excerpt": "excerpt",
        "Expand": "Expand",
        "Expand All": "Expand All",
        "Expand reportables": "Expand reportables",
        "Expectation Failed": "Expectation Failed",
        "Expected pieces": "Expected pieces",
        "Explanation": "Explanation",
        "Export": "Export",
        "Export {model}": "Export {model}",
        "Export {name}": "Export {name}",
        "Export Handler Overview": "Export Handler Overview",
        "Export Incident Overview": "Export Incident Overview",
        "Export loading times": "Export loading times",
        "Export Times Overview": "Export Times Overview",
        "Export trip handler overview": "Export trip handler overview",
        "External Link": "External Link",
        "External Test": "External Test",
        "External Tester": "External Tester",
        "Extra Abilities": "Extra Abilities",
        "Extra Loading Address Cost": "Extra Loading Address Cost",
        "Facebook link": "Facebook link",
        "Failed Dependency": "Failed Dependency",
        "Failed to find handler for air waybill {airWaybillNumber}": "Failed to find handler for air waybill {airWaybillNumber}",
        "Failed to read transit document for attachment {attachment}": "Failed to read transit document for attachment {attachment}",
        "Faq": "Faq",
        "Faqs": "Faqs",
        "Favorites": "Favorites",
        "favorites": "favorites",
        "Featured Image": "Featured Image",
        "Female": "Female",
        "FFM check": "FFM check",
        "File": "File",
        "Files": "Files",
        "Fill in the 6-digit code": "Fill in the 6-digit code",
        "Fill in this code in the app.": "Fill in this code in the app.",
        "Filter": "Filter",
        "Filters": "Filters",
        "Finalized": "Finalized",
        "Finance": "Finance",
        "Finish enabling two factor authentication.": "Finish enabling two factor authentication.",
        "Finish scan": "Finish scan",
        "Finished inbound scan": "Finished inbound scan",
        "Finished outbound scan": "Finished outbound scan",
        "First name": "First name",
        "First Noa Time": "First Noa Time",
        "First requested time": "First requested time",
        "First Stop": "First Stop",
        "First warehouse RCF received after": "First warehouse RCF received after",
        "Fixed": "Fixed",
        "Flat Fee Cost": "Flat Fee Cost",
        "Flight": "Flight",
        "flight": "flight",
        "Flight {flight} delayed, original ETA {originalArrivalTime}, current ETA {newArrivalTime}": "Flight {flight} delayed, original ETA {originalArrivalTime}, current ETA {newArrivalTime}",
        "Flight {model}": "Flight {model}",
        "Flight arrival": "Flight arrival",
        "Flight ATA": "Flight ATA",
        "Flight ETA": "Flight ETA",
        "Flight eta": "Flight eta",
        "Flight has not left LUX": "Flight has not left LUX",
        "Flight in sync": "Flight in sync",
        "Flight not found": "Flight not found",
        "Flight Number": "Flight Number",
        "Flight number": "Flight number",
        "Flight numbers": "Flight numbers",
        "Flight was delayed": "Flight was delayed",
        "Flights": "Flights",
        "Focal point": "Focal point",
        "Follow up to": "Follow up to",
        "Follow up tot": "Follow up tot",
        "Follow us": "Follow us",
        "Follow us on": "Follow us on",
        "For your security, please confirm your password to continue.": "For your security, please confirm your password to continue.",
        "Forbidden": "Forbidden",
        "Forgot password": "Forgot password",
        "Forgot password?": "Forgot password?",
        "Forgot your password?": "Forgot your password?",
        "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.": "Forgot your password? No problem. Just let us know your email address and we will email you a password reset link that will allow you to choose a new one.",
        "Forgot your password? Send a password reset link": "Forgot your password? Send a password reset link",
        "Forgotten password?": "Forgotten password?",
        "Forgotten pincode?": "Forgotten pincode?",
        "Form submission": "Form submission",
        "Form submissions": "Form submissions",
        "Found": "Found",
        "Found NOA for air waybill {number} which has no packages": "Found NOA for air waybill {number} which has no packages",
        "Free": "Free",
        "Free loading time": "Free loading time",
        "FREE SHIPPING WITH ORDERS OVER €75": "FREE SHIPPING WITH ORDERS OVER €75",
        "Free time": "Free time",
        "Free unloading time": "Free unloading time",
        "Friday": "Friday",
        "From": "From",
        "From address": "From address",
        "From addresses": "From addresses",
        "From Status": "From Status",
        "From status": "From status",
        "From the authenticator app": "From the authenticator app",
        "Fuel Per KM Cost": "Fuel Per KM Cost",
        "Full Address": "Full Address",
        "Full truck load": "Full truck load",
        "Full Truck Load Cost": "Full Truck Load Cost",
        "Furthermore, the NOA’s for shipment this shipment are attached to this email.": "Furthermore, the NOA’s for shipment this shipment are attached to this email.",
        "Furthermore, the NOA’s for this shipment are attached to this email.": "Furthermore, the NOA’s for this shipment are attached to this email.",
        "Fyco": "Fyco",
        "Fyco check": "Fyco check",
        "Fyco parcels": "Fyco parcels",
        "Fyco status": "Fyco status",
        "Fyco Status": "Fyco Status",
        "Gallery": "Gallery",
        "Gateway Timeout": "Gateway Timeout",
        "General slider": "General slider",
        "Generate can take quite some time. The report will be send to your email address when it is done.": "Generate can take quite some time. The report will be send to your email address when it is done.",
        "Generate variants": "Generate variants",
        "Generating these overviews takes a while.": "Generating these overviews takes a while.",
        "Germany": "Germany",
        "Global": "Global",
        "Global overviews": "Global overviews",
        "Global role": "Global role",
        "Global settings": "Global settings",
        "Go Home": "Go Home",
        "Go to page {page}": "Go to page {page}",
        "Gone": "Gone",
        "Good friday": "Good friday",
        "Google Authenticator": "Google Authenticator",
        "Google Maps URL": "Google Maps URL",
        "Google maps URL": "Google maps URL",
        "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-": "GRATIS VERZENDING BIJ BESTEDING VAN € 75,-",
        "Great! You have accepted the invitation to join the {team} team.": "Great! You have accepted the invitation to join the {team} team.",
        "Group": "Group",
        "Handler": "Handler",
        "handler": "Handler",
        "Handler / Stop": "Handler / Stop",
        "Handler DLV": "Handler DLV",
        "Handler RCF": "Handler RCF",
        "Handler Reference": "Handler Reference",
        "Handler reference": "Handler reference",
        "Handlers": "Handlers",
        "Has CMR/POD": "Has CMR/POD",
        "Has DLV": "Has DLV",
        "Has entered the last mile according to DLV messages": "Has entered the last mile according to DLV messages",
        "Has image": "Has image",
        "Has multiple clients": "Has multiple clients",
        "Has no flight": "Has no flight",
        "Has unresolved incident report": "Has unresolved incident report",
        "Have been delivered according to RCF messages": "Have been delivered according to RCF messages",
        "Have been put on a trip which has been planned": "Have been put on a trip which has been planned",
        "Heading": "Heading",
        "Hello": "Hello",
        "Hello Mr.CFO": "Hello Mr.CFO",
        "Hello!": "Hello!",
        "Hi": "Hi",
        "Hide": "Hide",
        "Hide {name}": "Hide {name}",
        "Hide completed": "Hide completed",
        "Hide on page": "Hide on page",
        "High": "High",
        "High explanation": "High explanation",
        "Highest quality": "Highest quality",
        "Hits": "Hits",
        "Home": "Home",
        "Hoogste kwaliteit": "Hoogste kwaliteit",
        "HTTP Version Not Supported": "HTTP Version Not Supported",
        "I agree to the {terms_of_service} and {privacy_policy}": "I agree to the {terms_of_service} and {privacy_policy}",
        "I am new here": "I am new here",
        "I have saved the backup codes": "I have saved the backup codes",
        "I'm a teapot": "I'm a teapot",
        "Icon": "Icon",
        "If an account with that email exists, you will receive an email shortly.": "If an account with that email exists, you will receive an email shortly.",
        "If enabled, and no image is uploaded, the banner from the homepage will be shown.": "If enabled, and no image is uploaded, the banner from the homepage will be shown.",
        "If left empty, the page title will be used.": "If left empty, the page title will be used.",
        "If left empty, the title will be used.": "If left empty, the title will be used.",
        "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.": "If necessary, you may log out of all of your other browser sessions across all of your devices. Some of your recent sessions are listed below; however, this list may not be exhaustive. If you feel your account has been compromised, you should also update your password.",
        "If you already have an account, you may accept this invitation by clicking the button below:": "If you already have an account, you may accept this invitation by clicking the button below:",
        "If you did not create an account, no further action is required.": "If you did not create an account, no further action is required.",
        "If you did not expect to receive an invitation to this team, you may discard this email.": "If you did not expect to receive an invitation to this team, you may discard this email.",
        "If you did not request a password reset, no further action is required.": "If you did not request a password reset, no further action is required.",
        "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:": "If you do not have an account, you may create one by clicking the button below. After creating an account, you may click the invitation acceptance button in this email to accept the team invitation:",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL below\ninto your web browser:",
        "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:": "If you're having trouble clicking the \"{actionText}\" button, copy and paste the URL belowninto your web browser:",
        "If you’d like to claim the trip you can do so via the tool in the Marketplace or click on the button below to open the request.": "If you’d like to claim the trip you can do so via the tool in the Marketplace or click on the button below to open the request.",
        "IM Used": "IM Used",
        "Image": "Image",
        "Image CTA": "Image CTA",
        "Images": "Images",
        "Impersonate": "Impersonate",
        "Impersonation": "Impersonation",
        "Import": "Import",
        "Import {name}": "Import {name}",
        "Import loading times": "Import loading times",
        "Inbound Air Waybills": "Inbound Air Waybills",
        "Inbound Scan {number}": "Inbound Scan {number}",
        "Inbound scanned": "Inbound scanned",
        "Inbounded scanned": "Inbounded scanned",
        "Incident rapport": "Incident rapport",
        "incident rapport": "incident rapport",
        "Incident Report": "Incident Report",
        "Incident report": "Incident report",
        "incident report": "incident report",
        "Incident report - {reportableName} - {type} - {createdAt}": "Incident report - {reportableName} - {type} - {createdAt}",
        "Incident report - {reportableType} {reportableName} - {type} - {createdAt}": "Incident report - {reportableType} {reportableName} - {type} - {createdAt}",
        "Incident report - {reportableType}{reportableName} - {type} - {createdAt}": "Incident report - {reportableType}{reportableName} - {type} - {createdAt}",
        "Incident report - {type}": "Incident report - {type}",
        "Incident report - {type} - {createdAt}": "Incident report - {type} - {createdAt}",
        "Incident Report Created": "Incident Report Created",
        "Incident Reports": "Incident Reports",
        "Include cancelled": "Include cancelled",
        "Include Completed": "Include Completed",
        "Include completely scanned": "Include completely scanned",
        "Include delivered": "Include delivered",
        "Include T1 on invoice": "Include T1 on invoice",
        "Including VAT": "Including VAT",
        "Index Page": "Index Page",
        "Inloggen": "Inloggen",
        "Inspection": "Inspection",
        "Instagram link": "Instagram link",
        "Instruction title": "Instruction title",
        "Insufficient Storage": "Insufficient Storage",
        "Internal comment": "Internal comment",
        "Internal comments": "Internal comments",
        "Internal Server Error": "Internal Server Error",
        "Introduction": "Introduction",
        "Invalid filename.": "Invalid filename.",
        "Invalid JSON was returned from the route.": "Invalid JSON was returned from the route.",
        "Invalid phone number": "Invalid phone number",
        "Invalid SSL Certificate": "Invalid SSL Certificate",
        "Invoiceable": "Invoiceable",
        "Invoiced": "Invoiced",
        "Invoices": "Invoices",
        "Is in the beginning of the process": "Is in the beginning of the process",
        "It is recommended to keep the {attribute} between {min} and {max} characters long": "It is recommended to keep the {attribute} between {min} and {max} characters long",
        "It will be send by email when done.": "It will be send by email when done.",
        "Its flight number is {number} and arrives at {date}.": "Its flight number is {number} and arrives at {date}.",
        "Its flight number is {number}.": "Its flight number is {number}.",
        "KVK number": "KVK number",
        "Label": "Label",
        "Label Button": "Label Button",
        "Language": "Language",
        "Last active": "Last active",
        "Last excel upload at: {time}": "Last excel upload at: {time}",
        "Last message on": "Last message on",
        "Last mile": "Last mile",
        "Last name": "Last name",
        "Last refresh was {quantity} {unit} ago": "Last refresh was {quantity} {unit} ago",
        "Last used": "Last used",
        "Latest noa time": "Latest noa time",
        "Latitude": "Latitude",
        "Lead Time": "Lead Time",
        "Lead time greater then 24 hours": "Lead time greater then 24 hours",
        "Lead Times": "Lead Times",
        "Leave": "Leave",
        "Leave empty for no end date": "Leave empty for no end date",
        "Leave empty to auto-generate": "Leave empty to auto-generate",
        "Leave empty to generate a random code. Not editable after creation.": "Leave empty to generate a random code. Not editable after creation.",
        "Leave empty to place in the marketplace when requested": "Leave empty to place in the marketplace when requested",
        "Leave empty to use parent details": "Leave empty to use parent details",
        "Leave empty to use separate loading and unloading times.": "Leave empty to use separate loading and unloading times.",
        "Leave Team": "Leave Team",
        "length": "length",
        "Length Required": "Length Required",
        "Length Used": "Length Used",
        "License plate": "License plate",
        "License plate (pulled unit)": "License plate (pulled unit)",
        "License plate {action} for trip {trip}": "License plate {action} for trip {trip}",
        "License plate pulled unit": "License plate pulled unit",
        "License plates": "License plates",
        "Like": "Like",
        "Link": "Link",
        "Link Button": "Link Button",
        "Link Flights to Package": "Link Flights to Package",
        "Linkedin link": "Linkedin link",
        "Load": "Load",
        "Load pickup": "Load pickup",
        "Loaded Excel": "Loaded Excel",
        "Loading": "Loading",
        "Loading arrival time": "Loading arrival time",
        "Loading list email addresses": "Loading list email addresses",
        "Loading list notification trigger": "Loading list notification trigger",
        "Loading list send": "Loading list send",
        "Loading list settings": "Loading list settings",
        "Loading time": "Loading time",
        "Loading Times": "Loading Times",
        "Loading times": "Loading times",
        "LoadingList {reference}": "LoadingList {reference}",
        "Localize": "Localize",
        "Location": "Location",
        "location": "location",
        "Location {model}": "Location {model}",
        "Location at:": "Location at:",
        "Locations": "Locations",
        "Locked": "Locked",
        "Log in": "Log in",
        "Log In": "Log In",
        "Log Out": "Log Out",
        "Log Out Other Browser Sessions": "Log Out Other Browser Sessions",
        "Login": "Login",
        "Login / Register": "Login / Register",
        "Login to your account in our secure environment": "Login to your account in our secure environment",
        "Login using pincode": "Login using pincode",
        "Login with a recovery code.": "Login with a recovery code.",
        "Logout": "Logout",
        "Longitude": "Longitude",
        "Looks like that not all cargo was retrieved from Swissport. Please check.": "Looks like that not all cargo was retrieved from Swissport. Please check.",
        "Looks like this air waybill {number} is handled by Challenge Group, but the handler is not a challenge handler.": "Looks like this air waybill {number} is handled by Challenge Group, but the handler is not a challenge handler.",
        "Looks like this air waybill {number} was shipped to another ground handlers by AviaPartner. It was booked on {bookedOn}.": "Looks like this air waybill {number} was shipped to another ground handlers by AviaPartner. It was booked on {bookedOn}.",
        "Loop Detected": "Loop Detected",
        "Loose": "Loose",
        "Low": "Low",
        "Low explanation": "Low explanation",
        "Made by:": "Made by:",
        "Mail addresses": "Mail addresses",
        "Main variant": "Main variant",
        "Maintenance Mode": "Maintenance Mode",
        "Make main": "Make main",
        "Make new pincode": "Make new pincode",
        "Make your decision:": "Make your decision:",
        "Male": "Male",
        "MAN Received": "MAN Received",
        "Manage Account": "Manage Account",
        "Manage and log out your active sessions on other browsers and devices.": "Manage and log out your active sessions on other browsers and devices.",
        "Manage API Tokens": "Manage API Tokens",
        "Manage Role": "Manage Role",
        "Manage Team": "Manage Team",
        "Many thanks for your cooperation.": "Many thanks for your cooperation.",
        "Mark all as read": "Mark all as read",
        "Mark as done": "Mark as done",
        "Mark as fyco done": "Mark as fyco done",
        "Mark as fyco needed": "Mark as fyco needed",
        "Mark as fyco prepared": "Mark as fyco prepared",
        "Mark as not delivered": "Mark as not delivered",
        "Mark as prepared": "Mark as prepared",
        "Mark as read": "Mark as read",
        "Mark as unread": "Mark as unread",
        "Marketplace": "Marketplace",
        "Marketplace email addresses": "Marketplace email addresses",
        "Marketplace messages": "Marketplace messages",
        "Marketplace Settings": "Marketplace Settings",
        "Master reference number": "Master reference number",
        "Max users": "Max users",
        "Medical": "Medical",
        "Meer informatie": "Meer informatie",
        "Member": "Member",
        "Members": "Members",
        "membership": "membership",
        "Menu": "Menu",
        "Menu items": "Menu items",
        "Menus": "Menus",
        "Message": "Message",
        "Messages": "Messages",
        "Method Not Allowed": "Method Not Allowed",
        "Metric Name": "Metric Name",
        "Microsoft Authenticator": "Microsoft Authenticator",
        "Milestones": "Milestones",
        "Minimal order amount": "Minimal order amount",
        "Minimum height": "Minimum height",
        "Minimum height of {height} cm": "Minimum height of {height} cm",
        "Misdirected Request": "Misdirected Request",
        "Mismatch T1 document for trip {number}": "Mismatch T1 document for trip {number}",
        "Missing flight": "Missing flight",
        "Missing license plates for trip {number}": "Missing license plates for trip {number}",
        "Missing loading list for trip {number}": "Missing loading list for trip {number}",
        "Missing pieces": "Missing pieces",
        "Missing requested loading times": "Missing requested loading times",
        "Missing some headers \"{headers}\"": "Missing some headers \"{headers}",
        "Mobile": "Mobile",
        "Monday": "Monday",
        "More": "More",
        "More settings can be set in the Page Block Settings page": "More settings can be set in the Page Block Settings page",
        "Most sold": "Most sold",
        "Move": "Move",
        "Move package to another truck": "Move package to another truck",
        "Move to location": "Move to location",
        "Moved Permanently": "Moved Permanently",
        "Multi-Status": "Multi-Status",
        "Multiple Choices": "Multiple Choices",
        "Multiple DLV's": "Multiple DLV's",
        "Multiple NOA's": "Multiple NOA's",
        "Multiple Noa's": "Multiple Noa's",
        "Multiple Rcf's or Dlv's": "Multiple Rcf's or Dlv's",
        "Multiple RCR's": "Multiple RCR's",
        "Mute client": "Mute client",
        "Muted": "Muted",
        "muted": "muted",
        "NA": "NA",
        "Name": "Name",
        "name": "name",
        "Name client": "Name client",
        "NCTS code": "NCTS code",
        "Nederland": "Nederland",
        "Netherlands": "Netherlands",
        "Network": "Network",
        "network": "Network",
        "Network Authentication Required": "Network Authentication Required",
        "Network Connect Timeout Error": "Network Connect Timeout Error",
        "Network Read Timeout Error": "Network Read Timeout Error",
        "Network(s)": "Network(s)",
        "Networks": "Networks",
        "New": "New",
        "New {name}": "New {name}",
        "New Password": "New Password",
        "New password": "New password",
        "New pincode": "New pincode",
        "New URL": "New URL",
        "Newest": "Newest",
        "Next": "Next",
        "NFD Received": "NFD Received",
        "NFD received, but no NOA {number}": "NFD received, but no NOA {number}",
        "No": "No",
        "No {model}": "No {model}",
        "No {model} selected": "No {model} selected",
        "No {models} left to add": "No {models} left to add",
        "No air waybills left": "No air waybills left",
        "No AWB available": "No AWB available",
        "No CMR": "No CMR",
        "No comments yet": "No comments yet",
        "No Content": "No Content",
        "No DLV times": "No DLV times",
        "No favorites yet": "No favorites yet",
        "No file uploaded": "No file uploaded",
        "No files": "No files",
        "No items": "No items",
        "No known excel upload time": "No known excel upload time",
        "No known opening hours for this day": "No known opening hours for this day",
        "No Loading List yet": "No Loading List yet",
        "No message": "No message",
        "No NOA received for {flightNumber} {date}": "No NOA received for {flightNumber} {date}",
        "No NOA times": "No NOA times",
        "No orders yet": "No orders yet",
        "No packages left": "No packages left",
        "No products found. Try again": "No products found. Try again",
        "No RCF times": "No RCF times",
        "No requirement": "No requirement",
        "No results": "No results",
        "No results found": "No results found",
        "No specific requirements": "No specific requirements",
        "No stacks left": "No stacks left",
        "No trigger": "No trigger",
        "No trips found": "No trips found",
        "No update": "No update",
        "No warehouse assigned": "No warehouse assigned",
        "NOA": "NOA",
        "NOA email addresses": "NOA email addresses",
        "NOA for {number} contained unexpected uld code": "NOA for {number} contained unexpected uld code",
        "NOA fragments": "NOA fragments",
        "Noa Mail Settings": "Noa Mail Settings",
        "NOA Mismatch {number}": "NOA Mismatch {number}",
        "NOA Received": "NOA Received",
        "NOA received": "NOA received",
        "NOA received after": "NOA received after",
        "NOA received before": "NOA received before",
        "NOA received between 20h - 24h ago, no DLV received": "NOA received between 20h - 24h ago, no DLV received",
        "Noa received between 20h - 24h ago, no dlv received": "Noa received between 20h - 24h ago, no dlv received",
        "Noa settings": "Noa settings",
        "NOA Time": "NOA Time",
        "NOA time": "NOA time",
        "Noa time": "Noa time",
        "Noa time from": "Noa time from",
        "Noa time to": "Noa time to",
        "NOA time(s)": "NOA time(s)",
        "NOAs": "NOAs",
        "Non-Authoritative Information": "Non-Authoritative Information",
        "Non-Rollerbed": "Non-Rollerbed",
        "None": "None",
        "Not Acceptable": "Not Acceptable",
        "Not all NOAs are available": "Not all NOAs are available",
        "Not available": "Not available",
        "Not available in this combination": "Not available in this combination",
        "Not Cleared": "Not Cleared",
        "Not Delivered": "Not Delivered",
        "Not editable after creation.": "Not editable after creation.",
        "Not Extended": "Not Extended",
        "Not Found": "Not Found",
        "Not Implemented": "Not Implemented",
        "Not implemented yet": "Not implemented yet",
        "Not Modified": "Not Modified",
        "Not scanned pieces": "Not scanned pieces",
        "not set": "not set",
        "Notes": "Notes",
        "Notes where created while in concept, might be out of date.": "Notes where created while in concept, might be out of date.",
        "Notification preference": "Notification preference",
        "Notification(s) of Arrival": "Notification(s) of Arrival",
        "Notifications": "Notifications",
        "Notify these email addresses": "Notify these email addresses",
        "Number": "Number",
        "Number of addresses": "Number of addresses",
        "Numbers": "Numbers",
        "of": "of",
        "Oh no! Someone will not know what to load when we don’t send the loadlist for {number}. The planned loading time is {loadingArrivalTime}.": "Oh no! Someone will not know what to load when we don’t send the loadlist for {number}. The planned loading time is {loadingArrivalTime}.",
        "Oh no! Someone will not know what to load when we don’t send the loadlist for {number}. The requested loading time is {requestedArrivalTime}.": "Oh no! Someone will not know what to load when we don’t send the loadlist for {number}. The requested loading time is {requestedArrivalTime}.",
        "OK": "OK",
        "Old loading list": "Old loading list",
        "Old URL": "Old URL",
        "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.": "On your phone or tablet with camera. We can advise you to use {google} or {microsoft}.",
        "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.": "Once a team is deleted, all of its resources and data will be permanently deleted. Before deleting this team, please download any data or information regarding this team that you wish to retain.",
        "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.": "Once your account is deleted, all of its resources and data will be permanently deleted. Before deleting your account, please download any data or information that you wish to retain.",
        "Only use this feature when driver is not allowed onto the premises and planner is not available to send one.": "Only use this feature when driver is not allowed onto the premises and planner is not available to send one.",
        "Open": "Open",
        "Open in a current window": "Open in a current window",
        "Open in a new window": "Open in a new window",
        "Open in a parent frame": "Open in a parent frame",
        "Open in new tab": "Open in new tab",
        "Open in the topmost frame": "Open in the topmost frame",
        "Open map": "Open map",
        "Open on the website": "Open on the website",
        "Open the authenticator app, add a new entry and scan the QR code": "Open the authenticator app, add a new entry and scan the QR code",
        "Opening hours": "Opening hours",
        "Opening hours: {from} - {till}": "Opening hours: {from} - {till}",
        "Openings hours": "Openings hours",
        "Options": "Options",
        "Orange Button": "Orange Button",
        "Order": "Order",
        "Order now": "Order now",
        "Order number": "Order number",
        "Order was created": "Order was created",
        "Orders": "Orders",
        "Origin Is Unreachable": "Origin Is Unreachable",
        "Our records show that Flight {flightNumber} has landed at {timestamp} carrying the following air waybills. However, we didn’t receive the NOA. Could you please advise on when the shipment will be available for pickup?": "Our records show that Flight {flightNumber} has landed at {timestamp} carrying the following air waybills. However, we didn’t receive the NOA. Could you please advise on when the shipment will be available for pickup?",
        "Outbound scanned": "Outbound scanned",
        "Overloaded": "Overloaded",
        "Overview": "Overview",
        "Overview request": "Overview request",
        "Owner": "Owner",
        "Package": "Package",
        "package": "package",
        "Package {packageNumber} on air waybill {airWaybillNumber} is assigned to {expectedHandler}, but received a NOA from {actualHandler}": "Package {packageNumber} on air waybill {airWaybillNumber} is assigned to {expectedHandler}, but received a NOA from {actualHandler}",
        "Package {packageNumber} on air waybill {airWaybillNumber} is assigned to {expectedHandler}, but received a NOA from an unknown email address": "Package {packageNumber} on air waybill {airWaybillNumber} is assigned to {expectedHandler}, but received a NOA from an unknown email address",
        "Package {uldCode} already contains air waybill {airWaybillNumber}": "Package {uldCode} already contains air waybill {airWaybillNumber}",
        "Package belongs to a different client": "Package belongs to a different client",
        "Package did not contain the air waybill": "Package did not contain the air waybill",
        "Package type": "Package type",
        "Packages": "Packages",
        "Packages as on {number}": "Packages as on {number}",
        "Packages making up {number}": "Packages making up {number}",
        "Packages of {number}": "Packages of {number}",
        "Page": "Page",
        "Page block settings": "Page block settings",
        "Page blocks": "Page blocks",
        "Page Content": "Page Content",
        "Page Expired": "Page Expired",
        "Page link": "Page link",
        "Page not found.": "Page not found.",
        "Page Title": "Page Title",
        "Pages": "Pages",
        "Pagination Navigation": "Pagination Navigation",
        "Paid": "Paid",
        "Pallet": "Pallet",
        "Pallet {number}": "Pallet {number}",
        "Pallet {number} has not been cleared by customs": "Pallet {number} has not been cleared by customs",
        "Pallet count": "Pallet count",
        "Pallet number": "Pallet number",
        "Pallet: {number}": "Pallet: {number}",
        "Pallets": "Pallets",
        "Pallets to combine": "Pallets to combine",
        "Paragraph": "Paragraph",
        "Parcel": "Parcel",
        "Parcel App": "Parcel App",
        "Parcel number": "Parcel number",
        "Parcel: {number}": "Parcel: {number}",
        "Parcels": "Parcels",
        "Parent category": "Parent category",
        "Partial air waybill": "Partial air waybill",
        "Partial Content": "Partial Content",
        "Partially not delivered": "Partially not delivered",
        "Password": "Password",
        "Password reset": "Password reset",
        "Password reset link has been sent": "Password reset link has been sent",
        "Paste or type the code we sent a code to your e-mail.": "Paste or type the code we sent a code to your e-mail.",
        "Paste or type the code we sent a code to your phone number.": "Paste or type the code we sent a code to your phone number.",
        "Payload Too Large": "Payload Too Large",
        "Payment": "Payment",
        "Payment Required": "Payment Required",
        "Payments": "Payments",
        "Pending Team Invitations": "Pending Team Invitations",
        "Pentecost": "Pentecost",
        "Pentecost monday": "Pentecost monday",
        "Percentage": "Percentage",
        "Perfect fit": "Perfect fit",
        "Perfecte pasvorm": "Perfecte pasvorm",
        "Permanent": "Permanent",
        "Permanent Redirect": "Permanent Redirect",
        "Permanently delete this team.": "Permanently delete this team.",
        "Permanently delete your account.": "Permanently delete your account.",
        "Permissions": "Permissions",
        "Phone": "Phone",
        "Phone number": "Phone number",
        "phone number": "phone number",
        "phone_number": "phone_number",
        "Photo": "Photo",
        "Pickup": "Pickup",
        "Pickup {number}": "Pickup {number}",
        "Pickup: {number}": "Pickup: {number}",
        "Pickups": "Pickups",
        "Piece": "Piece",
        "Piece number": "Piece number",
        "Piece Prefixes": "Piece Prefixes",
        "Piece: {number}": "Piece: {number}",
        "Pieces": "Pieces",
        "Pieces #": "Pieces #",
        "Pieces of air waybill total": "Pieces of air waybill total",
        "Pieces of package total": "Pieces of package total",
        "Pieces on {airWaybillNumber}": "Pieces on {airWaybillNumber}",
        "Pieces on pallet": "Pieces on pallet",
        "Pincode": "Pincode",
        "Place order": "Place order",
        "Planned": "Planned",
        "Planned Air Waybills": "Planned Air Waybills",
        "Planned Loading": "Planned Loading",
        "Planned Loading Time": "Planned Loading Time",
        "Planned loading time": "Planned loading time",
        "Planned loading time updated for trip {trip}": "Planned loading time updated for trip {trip}",
        "Planning instructions": "Planning instructions",
        "Please carefully check the planning and the NOA('s)": "Please carefully check the planning and the NOA('s)",
        "Please check if delivery is complete another possibility is that there are some left behinds and GH’r needs to be contacted.": "Please check if delivery is complete another possibility is that there are some left behinds and GH’r needs to be contacted.",
        "Please check loading meters, this wouldn’t fit by {meters}": "Please check loading meters, this wouldn’t fit by {meters}",
        "Please check loading meters, this wouldn’t fit by {meters} m": "Please check loading meters, this wouldn’t fit by {meters} m",
        "Please check the air waybill {number}.": "Please check the air waybill {number}.",
        "Please check the following AWB’s to ensure cargo will be ready for pickup on time:": "Please check the following AWB’s to ensure cargo will be ready for pickup on time:",
        "Please claim this trip if appropriate": "Please claim this trip if appropriate",
        "Please click the button below to verify your email address.": "Please click the button below to verify your email address.",
        "Please confirm access to your account by entering one of your emergency recovery codes.": "Please confirm access to your account by entering one of your emergency recovery codes.",
        "Please confirm access to your account by entering the authentication code provided by your authenticator application.": "Please confirm access to your account by entering the authentication code provided by your authenticator application.",
        "Please confirm your understanding of the requirements for this trip.": "Please confirm your understanding of the requirements for this trip.",
        "Please confirm your would like a new pincode": "Please confirm your would like a new pincode",
        "Please copy your new API token. For your security, it won't be shown again.": "Please copy your new API token. For your security, it won't be shown again.",
        "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.": "Please enter your password to confirm you would like to log out of your other browser sessions across all of your devices.",
        "Please find attached the loadinglist and the NOA’s for shipment {reference}.": "Please find attached the loadinglist and the NOA’s for shipment {reference}.",
        "Please find attached the loadinglist for shipment {reference}.": "Please find attached the loadinglist for shipment {reference}.",
        "Please invoice {count} T1s for trip {number}": "Please invoice {count} T1s for trip {number}",
        "Please let us know by adding a message below so we can send you the order directly if possible!": "Please let us know by adding a message below so we can send you the order directly if possible!",
        "Please note that the waiting hours on trip {number} have been set to 0.": "Please note that the waiting hours on trip {number} have been set to 0.",
        "Please note that we registered {waitingHours} waiting hours for trip {number}, concerning client(s) {clients}. This is a total of € {amount}.": "Please note that we registered {waitingHours} waiting hours for trip {number}, concerning client(s) {clients}. This is a total of € {amount}.",
        "Please note this notification is rate limited. Should this error happen again in the next {number} minutes, you will not be notified again. However the error will be logged in flare.": "Please note this notification is rate limited. Should this error happen again in the next {number} minutes, you will not be notified again. However the error will be logged in flare.",
        "Please provide a leg number, you can find the leg number request attached.<strong> Also, please advise from which building we can pick up the cargo.</strong>": "Please provide a leg number, you can find the leg number request attached.<strong> Also, please advise from which building we can pick up the cargo.</strong>",
        "Please provide the email address of the person you would like to add to this team.": "Please provide the email address of the person you would like to add to this team.",
        "Please reassign handler of the package, remove package from trip and reassign package to update the trip with the correct pickup address.": "Please reassign handler of the package, remove package from trip and reassign package to update the trip with the correct pickup address.",
        "Please review the loading times and adjust them accordingly.": "Please review the loading times and adjust them accordingly.",
        "Please review trips:": "Please review trips:",
        "Please set the handler with this prefix and add package(s) to the AWB so it can be planned for pickup.": "Please set the handler with this prefix and add package(s) to the AWB so it can be planned for pickup.",
        "Please set your password to activate your account.": "Please set your password to activate your account.",
        "Please use the link in your email to view your order.": "Please use the link in your email to view your order.",
        "PMCs": "PMCs",
        "POD": "POD",
        "POD missing for trip {number}": "POD missing for trip {number}",
        "PODs": "PODs",
        "Possible Noa": "Possible Noa",
        "Possible Rcf": "Possible Rcf",
        "Postal code": "Postal code",
        "Postcode": "Postcode",
        "Pre-planned": "Pre-planned",
        "Precondition Failed": "Precondition Failed",
        "Precondition Required": "Precondition Required",
        "Prefixes": "Prefixes",
        "Preloader": "Preloader",
        "Preview": "Preview",
        "Previous": "Previous",
        "Previously a loading list was send to {handlerNames}.": "Previously a loading list was send to {handlerNames}.",
        "Price": "Price",
        "Price: High - Low": "Price: High - Low",
        "Price: Low - High": "Price: Low - High",
        "Print": "Print",
        "Prioritize Air Waybill": "Prioritize Air Waybill",
        "Prioritized": "Prioritized",
        "Prioritized Air Waybill": "Prioritized Air Waybill",
        "Prioritized Air Waybills": "Prioritized Air Waybills",
        "Prioritized air waybills": "Prioritized air waybills",
        "Priority": "Priority",
        "Privacy declaration": "Privacy declaration",
        "Privacy page": "Privacy page",
        "Privacy Policy": "Privacy Policy",
        "Processing": "Processing",
        "Product": "Product",
        "Product details": "Product details",
        "Product information": "Product information",
        "Product option": "Product option",
        "Product options": "Product options",
        "Product Settings": "Product Settings",
        "Product slider": "Product slider",
        "Products": "Products",
        "Profile": "Profile",
        "Profile Information": "Profile Information",
        "Prologi": "Prologi",
        "Prologi found air waybill with number {number}": "Prologi found air waybill with number {number}",
        "Proxy Authentication Required": "Proxy Authentication Required",
        "Publish date": "Publish date",
        "Published At": "Published At",
        "Published from": "Published from",
        "Published until": "Published until",
        "Q": "Q",
        "QLS Data Export -": "QLS Data Export -",
        "QLS data export for {date}": "QLS data export for {date}",
        "QLS data export from {from} to {to}": "QLS data export from {from} to {to}",
        "QLS Handling - New Trip {number} On Marketplace": "QLS Handling - New Trip {number} On Marketplace",
        "QLS Handling - Trip {number} has {count} T1s": "QLS Handling - Trip {number} has {count} T1s",
        "QLS Handling - Waiting Hours Changed On Trip {number}": "QLS Handling - Waiting Hours Changed On Trip {number}",
        "Qr code": "Qr code",
        "Quantity": "Quantity",
        "Question": "Question",
        "Railgun Error": "Railgun Error",
        "Randomize image": "Randomize image",
        "Range Not Satisfiable": "Range Not Satisfiable",
        "RCF": "RCF",
        "RCF / DLV message": "RCF / DLV message",
        "RCF / DLV warehouse": "RCF / DLV warehouse",
        "RCF Handler": "RCF Handler",
        "RCF mail addresses": "RCF mail addresses",
        "RCF Messages": "RCF Messages",
        "RCF pieces": "RCF pieces",
        "RCF Received": "RCF Received",
        "RCF Time": "RCF Time",
        "RCF time": "RCF time",
        "RCF time(s)": "RCF time(s)",
        "RCS Received": "RCS Received",
        "RCT Received": "RCT Received",
        "Reactivate": "Reactivate",
        "Read more": "Read more",
        "Received {ordinal} NOA air waybill {awbNumber}": "Received {ordinal} NOA air waybill {awbNumber}",
        "Received at": "Received at",
        "Received NOA for concept trip": "Received NOA for concept trip",
        "Record": "Record",
        "Recovery Code": "Recovery Code",
        "Redirect": "Redirect",
        "Redirects": "Redirects",
        "Reference": "Reference",
        "Regards": "Regards",
        "Regards,": "Regards,",
        "Regenerate Recovery Codes": "Regenerate Recovery Codes",
        "Register": "Register",
        "Register to your account in our secure environment": "Register to your account in our secure environment",
        "Related products": "Related products",
        "Release note": "Release note",
        "Release notes": "Release notes",
        "Remark: {remark}": "Remark: {remark}",
        "Remarks": "Remarks",
        "Remember me": "Remember me",
        "Remove": "Remove",
        "Remove discount code": "Remove discount code",
        "Remove from favorites": "Remove from favorites",
        "remove one": "remove one",
        "Remove Photo": "Remove Photo",
        "remove product": "remove product",
        "Remove Stop #": "Remove Stop #",
        "Remove Team Member": "Remove Team Member",
        "Remove trip": "Remove trip",
        "Reopened subtask": "Reopened subtask",
        "Reportable": "Reportable",
        "Reported at": "Reported at",
        "Request Header Fields Too Large": "Request Header Fields Too Large",
        "Request new pincode": "Request new pincode",
        "Request RCF overview": "Request RCF overview",
        "Request Rcf Overview": "Request Rcf Overview",
        "Request report": "Request report",
        "Request Timeout": "Request Timeout",
        "Request trip": "Request trip",
        "Request trip anyway": "Request trip anyway",
        "Request warehouse RCF overview": "Request warehouse RCF overview",
        "Requested Loading": "Requested Loading",
        "Requested loading time": "Requested loading time",
        "Requested loading Time": "Requested loading Time",
        "Requested time": "Requested time",
        "Resend": "Resend",
        "Resend {attribute}": "Resend {attribute}",
        "Resend in": "Resend in",
        "Resend Verification Email": "Resend Verification Email",
        "Reserve truck": "Reserve truck",
        "Reserved By": "Reserved By",
        "Reset all filters": "Reset all filters",
        "Reset Content": "Reset Content",
        "Reset Password": "Reset Password",
        "Reset password": "Reset password",
        "Reset Password Notification": "Reset Password Notification",
        "Reset pincode": "Reset pincode",
        "Restore": "Restore",
        "Restore {name}": "Restore {name}",
        "Results": "results",
        "results": "results",
        "Results are shared": "Results are shared",
        "Retry With": "Retry With",
        "Reviews": "Reviews",
        "RGB color": "RGB color",
        "Role": "Role",
        "role": "role",
        "Roles": "Roles",
        "Route": "Route",
        "Row #": "Row #",
        "Sampled at": "Sampled at",
        "Saturday": "Saturday",
        "Save": "Save",
        "Save & Close": "Save & Close",
        "Save & Return": "Save & Return",
        "Save {name}": "Save {name}",
        "Save and add to new trip": "Save and add to new trip",
        "Save and request trip": "Save and request trip",
        "Save and stay": "Save and stay",
        "Save in favorites": "Save in favorites",
        "Save these codes on a secure place if you can’t get access with your Authenticator app.": "Save these codes on a secure place if you can’t get access with your Authenticator app.",
        "Saved.": "Saved.",
        "Scan": "Scan",
        "Scan piece": "Scan piece",
        "Scan qr code": "Scan qr code",
        "Scan the QR code": "Scan the QR code",
        "Scanned at": "Scanned at",
        "Scanning not possible?": "Scanning not possible?",
        "Scanning not possible? Fill in this code in the app.": "Scanning not possible? Fill in this code in the app.",
        "Seal number": "Seal number",
        "Search": "Search",
        "Search {name}": "Search {name}",
        "Search air waybills": "Search air waybills",
        "Search flight": "Search flight",
        "Search for pallet to combine": "Search for pallet to combine",
        "Search...": "Search...",
        "sec": "sec",
        "See Other": "See Other",
        "Select": "Select",
        "Select {model}": "Select {model}",
        "Select a {model}": "Select a {model}",
        "Select a {model} to add them": "Select a {model} to add them",
        "Select a air waybill": "Select a air waybill",
        "Select a flight": "Select a flight",
        "Select a handler": "Select a handler",
        "Select a location": "Select a location",
        "Select a network": "Select a network",
        "Select A New Photo": "Select A New Photo",
        "Select a package": "Select a package",
        "Select a package to add": "Select a package to add",
        "Select a package type to add": "Select a package type to add",
        "Select a page": "Select a page",
        "Select a pre-defined filter": "Select a pre-defined filter",
        "Select a preloader": "Select a preloader",
        "Select a reportable": "Select a reportable",
        "Select a role": "Select a role",
        "Select a stack to add": "Select a stack to add",
        "Select a status": "Select a status",
        "Select a template to add the corresponding blocks.": "Select a template to add the corresponding blocks.",
        "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created": "Select a template to add the corresponding blocks. Note that this cannot be changed after page has been created",
        "Select a test": "Select a test",
        "Select a trailer type": "Select a trailer type",
        "Select a transporter": "Select a transporter",
        "Select a trip": "Select a trip",
        "Select a truck": "Select a truck",
        "Select a type": "Select a type",
        "Select a user": "Select a user",
        "Select a warehouse": "Select a warehouse",
        "Select a warehouse address": "Select a warehouse address",
        "Select address": "Select address",
        "Select air waybill": "Select air waybill",
        "Select All": "Select All",
        "Select an address": "Select an address",
        "Select an option": "Select an option",
        "Select any day in the week you want a report on": "Select any day in the week you want a report on",
        "Select AWB": "Select AWB",
        "Select flight": "Select flight",
        "Select package type": "Select package type",
        "Select role": "Select role",
        "Select transporter": "Select transporter",
        "Select trip": "Select trip",
        "Select truck": "Select truck",
        "Select user": "Select user",
        "Select warehouse": "Select warehouse",
        "Select yes or no": "Select yes or no",
        "Send": "Send",
        "Send a password reset link": "Send a password reset link",
        "Send and return": "Send and return",
        "Send e-mail": "Send e-mail",
        "Send email": "Send email",
        "Send link": "Send link",
        "Send to Exact": "Send to Exact",
        "Send verification {attribute}": "Send verification {attribute}",
        "Seo Settings": "Seo Settings",
        "SEO title": "SEO title",
        "Separate aliases with commas": "Separate aliases with commas",
        "Server Error": "Server Error",
        "Service": "Service",
        "Service Unavailable": "Service Unavailable",
        "Session Has Expired": "Session Has Expired",
        "Set  password": "Set  password",
        "Set as active": "Set as active",
        "Set as inactive": "Set as inactive",
        "Set Two Factor Authentication": "Set Two Factor Authentication",
        "Set your password": "Set your password",
        "Set your secure password below": "Set your secure password below",
        "Sets": "Sets",
        "Settings": "Settings",
        "Setup Key": "Setup Key",
        "Sex": "Sex",
        "Share results": "Share results",
        "shared": "shared",
        "Shipments": "Shipments",
        "Shipping address": "Shipping address",
        "Shipping address same as billing address": "Shipping address same as billing address",
        "Shipping and return information": "Shipping and return information",
        "Shipping cost": "Shipping cost",
        "Shipping method": "Shipping method",
        "Shipping Rate": "Shipping Rate",
        "shipping rate": "shipping rate",
        "Shipping Rates": "Shipping Rates",
        "Shop": "Shop",
        "Shopping cart": "Shopping cart",
        "Shopping cart page": "Shopping cart page",
        "Short code": "Short code",
        "Short name": "Short name",
        "Should you wish continue with the change, without sending this message, press \"Update silently\"": "Should you wish continue with the change, without sending this message, press \"Update silently",
        "Show": "Show",
        "Show {name}": "Show {name}",
        "Show All": "Show All",
        "Show completed": "Show completed",
        "Show less": "Show less",
        "Show more": "Show more",
        "Show Recovery Codes": "Show Recovery Codes",
        "Showing": "Showing",
        "sidebar": "sidebar",
        "Sign In": "Sign In",
        "Sign out": "Sign out",
        "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.": "Since it’s your first time logging in here, we’ve sent a code to your phone to verify your user profile.",
        "Single ULD Cost": "Single ULD Cost",
        "Size chart image": "Size chart image",
        "Slides": "Slides",
        "Slug": "Slug",
        "Solve": "Solve",
        "Some of the air waybills do not have a NOA.": "Some of the air waybills do not have a NOA.",
        "Some of the shipments do not have a requested loading time.": "Some of the shipments do not have a requested loading time.",
        "Sort": "Sort",
        "SSL Handshake Failed": "SSL Handshake Failed",
        "Stack": "Stack",
        "stack": "stack",
        "stack trip": "stack trip",
        "Stacks": "Stacks",
        "Stacks as on {number}": "Stacks as on {number}",
        "Stacks on {number}": "Stacks on {number}",
        "Standard": "Standard",
        "Start": "Start",
        "Start loading time": "Start loading time",
        "Start new pallet": "Start new pallet",
        "Start scanning": "Start scanning",
        "Start time {day}": "Start time {day}",
        "Start unloading time": "Start unloading time",
        "Started at": "Started at",
        "Started inbound scan": "Started inbound scan",
        "Started outbound scan": "Started outbound scan",
        "Status": "Status",
        "status": "Status",
        "Statuses": "Statuses",
        "Stock": "Stock",
        "Stop": "Stop",
        "Stops": "Stops",
        "Stops on {number}": "Stops on {number}",
        "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.": "Store these recovery codes in a secure password manager. They can be used to recover access to your account if your two factor authentication device is lost.",
        "Street name": "Street name",
        "Street number": "Street number",
        "Street number addition": "Street number addition",
        "Sub menu": "Sub menu",
        "Subject": "Subject",
        "Submit": "Submit",
        "Subscribe": "Subscribe",
        "Subscribe for our newsletter": "Subscribe for our newsletter",
        "SubTasks": "SubTasks",
        "Subtotal": "Subtotal",
        "Sunday": "Sunday",
        "Swissport": "Swissport",
        "Swissport has undelivered air waybills for trip {trip}": "Swissport has undelivered air waybills for trip {trip}",
        "Switch": "Switch",
        "Switch Teams": "Switch Teams",
        "Switch To Role": "Switch To Role",
        "Switching Protocols": "Switching Protocols",
        "Sync": "Sync",
        "T1 cost": "T1 cost",
        "T1 email addresses": "T1 email addresses",
        "T1 for shipment {reference}": "T1 for shipment {reference}",
        "T1 for shipment {reference} ({handlerReference})": "T1 for shipment {reference} ({handlerReference})",
        "T1 phone number": "T1 phone number",
        "T1 received": "T1 received",
        "T1 received at": "T1 received at",
        "T1 settings": "T1 settings",
        "Tag": "Tag",
        "Tags": "Tags",
        "Task": "Task",
        "Tasks": "Tasks",
        "Team Details": "Team Details",
        "Team Invitation": "Team Invitation",
        "Team Members": "Team Members",
        "Team Name": "Team Name",
        "Team Owner": "Team Owner",
        "Team Settings": "Team Settings",
        "Tel": "Tel",
        "Temporary Redirect": "Temporary Redirect",
        "Terms and Conditions": "Terms and Conditions",
        "Terms of Service": "Terms of Service",
        "Terms page": "Terms page",
        "Test": "Test",
        "test": "test",
        "Test Notification": "Test Notification",
        "Test set": "Test set",
        "test set": "test set",
        "Test sets": "Test sets",
        "Tests": "Tests",
        "Text": "Text",
        "Thank you for shopping with us!": "Thank you for shopping with us!",
        "Thank you for your message.": "Thank you for your message.",
        "Thank you page settings": "Thank you page settings",
        "The {attribute} has already been taken.": "The {attribute} has already been taken.",
        "The {attribute} may only have two decimal places.": "The {attribute} may only have two decimal places.",
        "The {attribute} must be a valid role.": "The {attribute} must be a valid role.",
        "The {attribute} must be at least {length} characters and contain at least one number.": "The {attribute} must be at least {length} characters and contain at least one number.",
        "The {attribute} must be at least {length} characters and contain at least one special character and one number.": "The {attribute} must be at least {length} characters and contain at least one special character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one special character.": "The {attribute} must be at least {length} characters and contain at least one special character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one number.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character and one special character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character, one number, and one special character.",
        "The {attribute} must be at least {length} characters and contain at least one uppercase character.": "The {attribute} must be at least {length} characters and contain at least one uppercase character.",
        "The {attribute} must be at least {length} characters.": "The {attribute} must be at least {length} characters.",
        "The {attribute} must be verified.": "The {attribute} must be verified.",
        "The {attribute} must contain at least one letter.": "The {attribute} must contain at least one letter.",
        "The {attribute} must contain at least one number.": "The {attribute} must contain at least one number.",
        "The {attribute} must contain at least one symbol.": "The {attribute} must contain at least one symbol.",
        "The {attribute} must contain at least one uppercase and one lowercase letter.": "The {attribute} must contain at least one uppercase and one lowercase letter.",
        "The air waybill {awbNumber} is missing the RCF message, while the inbound scan has started.": "The air waybill {awbNumber} is missing the RCF message, while the inbound scan has started.",
        "The air waybill {awbNumber} is missing the RCF message. While it's trip {tripNumber} was delivered": "The air waybill {awbNumber} is missing the RCF message. While it's trip {tripNumber} was delivered",
        "The air waybill {number} has no packages": "The air waybill {number} has no packages",
        "The air waybill {number} has received a NOA but the trip {trip} is still in concept.": "The air waybill {number} has received a NOA but the trip {trip} is still in concept.",
        "The air waybill {number} is missing the RCF message": "The air waybill {number} is missing the RCF message",
        "The air waybill {number} received an RCF message from the future": "The air waybill {number} received an RCF message from the future",
        "The air waybill {number} received an RCF message with to many pieces": "The air waybill {number} received an RCF message with to many pieces",
        "The air waybill number {number} was found in Prologi, not all off the packages belonging to this air waybill have a trip.": "The air waybill number {number} was found in Prologi, not all off the packages belonging to this air waybill have a trip.",
        "The air waybill was (partially) not delivered": "The air waybill was (partially) not delivered",
        "The changes were:": "The changes were:",
        "The client(s) for this trip are {clients}": "The client(s) for this trip are {clients}",
        "The coupon has already been used.": "The coupon has already been used.",
        "The coupon has expired.": "The coupon has expired.",
        "The entire trip needs to start or end at the same address.": "The entire trip needs to start or end at the same address.",
        "The first shipment requires a arrival time of {datetime}": "The first shipment requires a arrival time of {datetime}",
        "The flight {flight} was delayed and will arrive at {newArrivalTime}": "The flight {flight} was delayed and will arrive at {newArrivalTime}",
        "The following air waybills have an ATA of {timestamp}, but the NOA has not been received yet. We are actively following up and coordinating with the ground handler to expedite the process.": "The following air waybills have an ATA of {timestamp}, but the NOA has not been received yet. We are actively following up and coordinating with the ground handler to expedite the process.",
        "The following NOA(s) were received:": "The following NOA(s) were received:",
        "The following package(s) are planned:": "The following package(s) are planned:",
        "The following trips have submitted waiting hours:": "The following trips have submitted waiting hours:",
        "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}.",
        "The given data was invalid.": "The given data was invalid.",
        "The inbound scan has finished": "The inbound scan has finished",
        "The inbound scan has started": "The inbound scan has started",
        "The latest trends": "The latest trends",
        "The link is expired or invalid.": "The link is expired or invalid.",
        "The loading time is planned for {loadingArrivalTime}.": "The loading time is planned for {loadingArrivalTime}.",
        "The loading times for shipment {number} have been declined, with the following message:.": "The loading times for shipment {number} have been declined, with the following message:.",
        "The minimum height requirement for this trip is {height} cm": "The minimum height requirement for this trip is {height} cm",
        "The NFD for AWB {number} has been received, but no NOA has been received.": "The NFD for AWB {number} has been received, but no NOA has been received.",
        "The outbound scan has finished": "The outbound scan has finished",
        "The outbound scan has started": "The outbound scan has started",
        "The password field is required when creating an account.": "The password field is required when creating an account.",
        "The password is incorrect.": "The password is incorrect.",
        "The planned arrival was {previousLoadingArrivalTime} and now is {loadingArrivalTime}": "The planned arrival was {previousLoadingArrivalTime} and now is {loadingArrivalTime}",
        "The planned loading time for the trip {number} has been updated by the transporter company {transporterName}": "The planned loading time for the trip {number} has been updated by the transporter company {transporterName}",
        "The planned loading time for the trip {number} with a requested loading time {requestedArrivalTime} has been updated by the transporter company {transporterName}": "The planned loading time for the trip {number} with a requested loading time {requestedArrivalTime} has been updated by the transporter company {transporterName}",
        "The planned loading time for the trip {number} with requested loading time {requestedArrivalTime}, was set to be {loadingArrivalTime} by the transporter company {transporterName}": "The planned loading time for the trip {number} with requested loading time {requestedArrivalTime}, was set to be {loadingArrivalTime} by the transporter company {transporterName}",
        "The prioritized air waybill {airWaybill} has received a NOA": "The prioritized air waybill {airWaybill} has received a NOA",
        "The prioritized air waybill {airWaybill} has received a NOA. Its shipment {reference} was prioritized, we just received the NOA, check if you can pick it up earlier.": "The prioritized air waybill {airWaybill} has received a NOA. Its shipment {reference} was prioritized, we just received the NOA, check if you can pick it up earlier.",
        "The provided {attribute} is invalid.": "The provided {attribute} is invalid.",
        "The provided code was invalid.": "The provided code was invalid.",
        "The provided password does not match your current password.": "The provided password does not match your current password.",
        "The provided password was incorrect.": "The provided password was incorrect.",
        "The provided two factor authentication code was invalid.": "The provided two factor authentication code was invalid.",
        "The provided two factor recovery code was invalid.": "The provided two factor recovery code was invalid.",
        "The response is not a streamed response.": "The response is not a streamed response.",
        "The response is not a view.": "The response is not a view.",
        "The status of trip {number} has been updated to {status}": "The status of trip {number} has been updated to {status}",
        "The team's name and owner information.": "The team's name and owner information.",
        "The tests cannot be changed once the test set is created.": "The tests cannot be changed once the test set is created.",
        "The trailer type for this trip is {type}": "The trailer type for this trip is {type}",
        "The transporter {name} has claimed the trip {number}": "The transporter {name} has claimed the trip {number}",
        "The trip {number} cannot have multiple warehouses": "The trip {number} cannot have multiple warehouses",
        "The trip {number} has {count} T1s": "The trip {number} has {count} T1s",
        "The trip will be updated, please check it afterwards.": "The trip will be updated, please check it afterwards.",
        "The ULD code is already in use for this air waybill.": "The ULD code is already in use for this air waybill.",
        "The ULD code is already in use for this trip.": "The ULD code is already in use for this trip.",
        "The ULD code is already in use of a package of the same type for this trip.": "The ULD code is already in use of a package of the same type for this trip.",
        "The user is required": "The user is required",
        "The waiting hours on trip {number} has been changed": "The waiting hours on trip {number} has been changed",
        "The warehouse of air waybill {numbers} where changed from {oldWarehouse} to {newWarehouse}": "The warehouse of air waybill {numbers} where changed from {oldWarehouse} to {newWarehouse}",
        "The weekly RCF overview.": "The weekly RCF overview.",
        "Them: {theirNumber}": "Them: {theirNumber}",
        "There are the trips still open for the coming 24 hours:": "There are the trips still open for the coming 24 hours:",
        "There has been a new incident report created for a {model}.": "There has been a new incident report created for a {model}.",
        "There is a trip planned with reference {reference}, for loading on {time}": "There is a trip planned with reference {reference}, for loading on {time}",
        "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.": "These people have been invited to your team and have been sent an invitation email. They may join the team by accepting the email invitation.",
        "This {attribute} has already been used": "This {attribute} has already been used",
        "This action is unauthorized.": "This action is unauthorized.",
        "This area is restricted to medical medic members only.": "This area is restricted to medical medic members only.",
        "This area is restricted to staff members only.": "This area is restricted to staff members only.",
        "This concerns air waybills numbers {airWaybillNumber}": "This concerns air waybills numbers {airWaybillNumber}",
        "This concerns trip numbers {trips}": "This concerns trip numbers {trips}",
        "This device": "This device",
        "This flight has no known positions": "This flight has no known positions",
        "This is a secure area of the application. Please confirm your password before continuing.": "This is a secure area of the application. Please confirm your password before continuing.",
        "This is a test notification, with a very long body to test the notification system. Proin et auctor magna, quis semper tortor. In tristique nunc nec scelerisque porttitor. Suspendisse eu sem ut arcu tempus convallis et eu neque. Quisque fermentum, eros ac facilisis tempus, nulla dui bibendum lacus, at dignissim neque quam at nisl. Curabitur malesuada vel neque ut faucibus. Praesent consectetur orci tellus, eget rhoncus orci varius vel. Maecenas tempor lorem lorem. Nunc tellus magna, tincidunt id ante a, luctus sollicitudin nisl. Aenean ut leo sed urna cursus tristique. Nam et sodales risus. In nunc lectus, tincidunt id commodo quis, facilisis vel tortor. Morbi lectus nibh, condimentum eu molestie sed, interdum id est. Donec sit amet urna auctor, ultrices nibh sed, molestie ex. Nulla dolor lacus, tempus vel accumsan sed, tincidunt non arcu.": "This is a test notification, with a very long body to test the notification system. Proin et auctor magna, quis semper tortor. In tristique nunc nec scelerisque porttitor. Suspendisse eu sem ut arcu tempus convallis et eu neque. Quisque fermentum, eros ac facilisis tempus, nulla dui bibendum lacus, at dignissim neque quam at nisl. Curabitur malesuada vel neque ut faucibus. Praesent consectetur orci tellus, eget rhoncus orci varius vel. Maecenas tempor lorem lorem. Nunc tellus magna, tincidunt id ante a, luctus sollicitudin nisl. Aenean ut leo sed urna cursus tristique. Nam et sodales risus. In nunc lectus, tincidunt id commodo quis, facilisis vel tortor. Morbi lectus nibh, condimentum eu molestie sed, interdum id est. Donec sit amet urna auctor, ultrices nibh sed, molestie ex. Nulla dolor lacus, tempus vel accumsan sed, tincidunt non arcu.",
        "This is a test notification.": "This is a test notification.",
        "This is your first login": "This is your first login",
        "This link has expired, a new one has been sent to your email.": "This link has expired, a new one has been sent to your email.",
        "This password does not match our records.": "This password does not match our records.",
        "This password reset link will expire in {count} minutes.": "This password reset link will expire in {count} minutes.",
        "This pincode reset link is no longer invalid, please request a new one.": "This pincode reset link is no longer invalid, please request a new one.",
        "This shipping rate is not applicable to your cart.": "This shipping rate is not applicable to your cart.",
        "This task is reserved by {userName}": "This task is reserved by {userName}",
        "This task is reserved by {userName}.": "This task is reserved by {userName}.",
        "This trip has no known positions": "This trip has no known positions",
        "This trip is not yet claimed by anyone.": "This trip is not yet claimed by anyone.",
        "This user already belongs to the team.": "This user already belongs to the team.",
        "This user has already been invited to the team.": "This user has already been invited to the team.",
        "This will move the air waybill included packages and copy over flight to the selected location.": "This will move the air waybill included packages and copy over flight to the selected location.",
        "This will move the stack to the selected handler.": "This will move the stack to the selected handler.",
        "This will move the stack to the selected location.": "This will move the stack to the selected location.",
        "This will overwrite the current ULD codes": "This will overwrite the current ULD codes",
        "Thursday": "Thursday",
        "Tiktoc link": "Tiktoc link",
        "Till": "Till",
        "Times": "Times",
        "Times Export": "Times Export",
        "Times used": "Times used",
        "Title": "Title",
        "title": "title",
        "To": "To",
        "to": "to",
        "To address": "To address",
        "To addresses": "To addresses",
        "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.": "To finish enabling two factor authentication, scan the following QR code using your phone's authenticator application or enter the setup key and provide the generated OTP code.",
        "To Status": "To Status",
        "To status": "To status",
        "TODO": "TODO",
        "Toggle navigation": "Toggle navigation",
        "token": "token",
        "Token Name": "Token Name",
        "Too Early": "Too Early",
        "Too Many Requests": "Too Many Requests",
        "Totaal products ({number})": "Totaal products ({number})",
        "Total": "Total",
        "Total pieces": "Total pieces",
        "Total saving": "Total saving",
        "Trailer": "Trailer",
        "Trailer type": "Trailer type",
        "Trailer type of {type}": "Trailer type of {type}",
        "Trailer type: {type}": "Trailer type: {type}",
        "Trans": "Trans",
        "Transit document mail addresses": "Transit document mail addresses",
        "Transit document phone numbers": "Transit document phone numbers",
        "Transit documents": "Transit documents",
        "Transit documents notification text": "Transit documents notification text",
        "Translate": "Translate",
        "Translate It": "Translate It",
        "Transport instructions": "Transport instructions",
        "Transporter": "Transporter",
        "transporter": "transporter",
        "Transporter {name} has claimed the trip {number}": "Transporter {name} has claimed the trip {number}",
        "Transporter Companies": "Transporter Companies",
        "transporter companies": "transporter companies",
        "Transporter Company": "Transporter Company",
        "Transporters": "Transporters",
        "Tried to automatically send loading list notification for trip {number}, but it is missing requested loading time.": "Tried to automatically send loading list notification for trip {number}, but it is missing requested loading time.",
        "Trip": "Trip",
        "trip": "Trip",
        "Trip {number} has not been delivered near closing": "Trip {number} has not been delivered near closing",
        "Trip {number} is missing requested loading time": "Trip {number} is missing requested loading time",
        "Trip {number} isn’t yet delivered at {addressableName} while it closes at {time} today, please check with driver if there’s enough time to unload.": "Trip {number} isn’t yet delivered at {addressableName} while it closes at {time} today, please check with driver if there’s enough time to unload.",
        "Trip {number} started loading at {time} was planned to pickup the following AWB’s:": "Trip {number} started loading at {time} was planned to pickup the following AWB’s:",
        "Trip {tripNumber} is not yet finalized please check to finalize shipment.": "Trip {tripNumber} is not yet finalized please check to finalize shipment.",
        "Trip {tripNumber} is not yet finalized.": "Trip {tripNumber} is not yet finalized.",
        "Trip cancellation": "Trip cancellation",
        "Trip from date": "Trip from date",
        "Trip from status": "Trip from status",
        "Trip Overview": "Trip Overview",
        "Trip Status": "Trip Status",
        "Trip status updated": "Trip status updated",
        "Trip to date": "Trip to date",
        "Trip to status": "Trip to status",
        "Trip type": "Trip type",
        "Trip was changed since last update to third parties": "Trip was changed since last update to third parties",
        "Trip was finalized at {time}": "Trip was finalized at {time}",
        "Triple ULD Cost": "Triple ULD Cost",
        "Trips": "Trips",
        "trips": "Trips",
        "Trips with waiting hours": "Trips with waiting hours",
        "Truck": "Truck",
        "Truck {number} was requested for {requestedTime} but planned for {plannedTime}. The difference is {hours} hours and {minutes} minutes.": "Truck {number} was requested for {requestedTime} but planned for {plannedTime}. The difference is {hours} hours and {minutes} minutes.",
        "Truck hasn't left yet": "Truck hasn't left yet",
        "Truck is standing still": "Truck is standing still",
        "Truck planned later than requested time": "Truck planned later than requested time",
        "Tuesday": "Tuesday",
        "Two Factor Authentication": "Two Factor Authentication",
        "Two Factor Authentication - Backup codes": "Two Factor Authentication - Backup codes",
        "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.": "Two factor authentication is now enabled. Scan the following QR code using your phone's authenticator application or enter the setup key.",
        "Two Factor Authentication is required as extra security, you can set this now": "Two Factor Authentication is required as extra security, you can set this now",
        "Two factor challenge": "Two factor challenge",
        "Two-factor Confirmation": "Two-factor Confirmation",
        "Type": "Type",
        "UC Transport": "UC Transport",
        "ULD": "ULD",
        "Uld": "Uld",
        "ULD code": "ULD code",
        "Uld code": "Uld code",
        "ULD codes": "ULD codes",
        "Ulds": "Ulds",
        "Unassigned": "Unassigned",
        "Unauthorized": "Unauthorized",
        "Unavailable For Legal Reasons": "Unavailable For Legal Reasons",
        "Unisex": "Unisex",
        "Unit price": "Unit price",
        "Unknown": "Unknown",
        "Unknown Error": "Unknown Error",
        "Unloaded Excel": "Unloaded Excel",
        "Unloading": "Unloading",
        "Unloading time": "Unloading time",
        "Unloading wait time": "Unloading wait time",
        "Unmute client": "Unmute client",
        "unmuted": "unmuted",
        "Unpack": "Unpack",
        "Unprocessable Entity": "Unprocessable Entity",
        "Unresolved incident report": "Unresolved incident report",
        "Unsubscribe": "Unsubscribe",
        "Unsupported Media Type": "Unsupported Media Type",
        "Up": "Up",
        "Update": "Update",
        "Update {model}": "Update {model}",
        "Update {name}": "Update {name}",
        "Update account": "Update account",
        "Update anyway": "Update anyway",
        "Update comment": "Update comment",
        "Update package": "Update package",
        "Update package {uldCode}": "Update package {uldCode}",
        "Update parties": "Update parties",
        "Update Password": "Update Password",
        "Update pickup": "Update pickup",
        "Update shipment": "Update shipment",
        "Update silently": "Update silently",
        "Update stack": "Update stack",
        "Update trip": "Update trip",
        "Update warehouse": "Update warehouse",
        "Update your account's profile information and email address.": "Update your account's profile information and email address.",
        "Update your info here.": "Update your info here.",
        "updated": "updated",
        "Updated at: {time}": "Updated at: {time}",
        "Updated by {name}": "Updated by {name}",
        "Upgrade Required": "Upgrade Required",
        "Upload": "Upload",
        "Upload Excel": "Upload Excel",
        "Upload files": "Upload files",
        "Uploading T1’s was disabled for this handler to avoid sending unnecessary emails. If you want to enable this, please contact planning@qlshandling.com": "Uploading T1’s was disabled for this handler to avoid sending unnecessary emails. If you want to enable this, please contact planning{'@'}qlshandling.com",
        "URI Too Long": "URI Too Long",
        "Url": "Url",
        "url": "url",
        "Us: {number}, them: {theirNumber}": "Us: {number}, them: {theirNumber}",
        "Use `-1` for unlimited usage": "Use `-1` for unlimited usage",
        "Use a recovery code": "Use a recovery code",
        "Use an authentication code": "Use an authentication code",
        "Use Proxy": "Use Proxy",
        "Used for barcodes": "Used for barcodes",
        "Used for RCF / scan log checks": "Used for RCF / scan log checks",
        "Used for trip requests / loading lists": "Used for trip requests / loading lists",
        "Used in reference and such": "Used in reference and such",
        "User": "User",
        "user": "user",
        "Users": "Users",
        "USPs": "USPs",
        "Uw winkelmand is leeg": "Uw winkelmand is leeg",
        "Value": "Value",
        "Values": "Values",
        "Variant": "Variant",
        "Variant Also Negotiates": "Variant Also Negotiates",
        "Variants": "Variants",
        "VAT": "VAT",
        "Verify": "Verify",
        "Verify {attribute}": "Verify {attribute}",
        "Verify Email Address": "Verify Email Address",
        "Verify your phone number": "Verify your phone number",
        "View": "View",
        "View {name}": "View {name}",
        "View Air Waybill": "View Air Waybill",
        "View air waybill": "View air waybill",
        "View incident": "View incident",
        "View order": "View order",
        "View overview": "View overview",
        "View Trip": "View Trip",
        "View trip": "View trip",
        "Waiting time": "Waiting time",
        "Waiting Time Per Hour Cost": "Waiting Time Per Hour Cost",
        "Warehouse": "Warehouse",
        "warehouse": "warehouse",
        "Warehouse DLV": "Warehouse DLV",
        "Warehouse Overview": "Warehouse Overview",
        "Warehouse RCF": "Warehouse RCF",
        "Warehouses": "Warehouses",
        "Warning: limited stock!": "Warning: limited stock!",
        "Was claimed through marketplace": "Was claimed through marketplace",
        "Was send to Exact": "Was send to Exact",
        "Was unable to find an address with this postcode and street number": "Was unable to find an address with this postcode and street number",
        "Washing instructions": "Washing instructions",
        "We are still missing the license plates for trip {number}.": "We are still missing the license plates for trip {number}.",
        "We did not receive the POD for the trip {number}, could you please upload it in the tool?": "We did not receive the POD for the trip {number}, could you please upload it in the tool?",
        "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.": "We have done our best, but it seems that the page you requested does not exist (anymore) or that it may have moved.",
        "We were unable to find a registered user with this email address.": "We were unable to find a registered user with this email address.",
        "Web Page": "Web Page",
        "Web Page Settings": "Web Page Settings",
        "Web Server is Down": "Web Server is Down",
        "Website": "Website",
        "Wednesday": "Wednesday",
        "Week of": "Week of",
        "Weekly Rcf Overview {date}": "Weekly Rcf Overview {date}",
        "Weight": "Weight",
        "Welcome": "Welcome",
        "Welcome back": "Welcome back",
        "Welcome to {page}": "Welcome to {page}",
        "Welcome to QLS handling": "Welcome to QLS handling",
        "We’ve a trip {number} available for loading between {requestedLoadingTime} and {maxAllowedLoadingTime}": "We’ve a trip {number} available for loading between {requestedLoadingTime} and {maxAllowedLoadingTime}",
        "What is my size?": "What is my size?",
        "When a NOA for an unknown air waybill is send to one of these email addresses, notify the other email addresses": "When a NOA for an unknown air waybill is send to one of these email addresses, notify the other email addresses",
        "When a unknown NOA is send to one of these email addresses": "When a unknown NOA is send to one of these email addresses",
        "When enabled and the handler has a t1 cost set, T1 will be included on the invoice.": "When enabled and the handler has a t1 cost set, T1 will be included on the invoice.",
        "When enabled, an email will be sent to the user, letting them know their account has been created.": "When enabled, an email will be sent to the user, letting them know their account has been created.",
        "When NOA is found for unknown air waybill number, which has these email addresses in the \\'From\\' field, notify the email addresses in \\'Email addresses to notify when NOA is found for unknown air waybill number\\' field. Separate multiple email addresses with a comma.": "When NOA is found for unknown air waybill number, which has these email addresses in the \\'From\\' field, notify the email addresses in \\'Email addresses to notify when NOA is found for unknown air waybill number\\' field. Separate multiple email addresses with a comma.",
        "When set and the location setting is enabled, T1 cost will be included on the invoice.": "When set and the location setting is enabled, T1 cost will be included on the invoice.",
        "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.": "When two factor authentication is enabled, you will be prompted for a secure, random token during authentication. You may retrieve this token from your phone's Google Authenticator application.",
        "Whoops!": "Whoops!",
        "Whoops! Something went wrong.": "Whoops! Something went wrong.",
        "Yes": "Yes",
        "Yes at {dateTime}": "Yes at {dateTime}",
        "You account has been created.": "You account has been created.",
        "You are receiving this email because we received a password reset request for your account.": "You are receiving this email because we received a password reset request for your account.",
        "You are subscribed to the newsletter": "You are subscribed to the newsletter",
        "You cannot cancel a trip with cargo. Please remove the cargo first.": "You cannot cancel a trip with cargo. Please remove the cargo first.",
        "You have been invited to join the {team} team!": "You have been invited to join the {team} team!",
        "You have changed the status of the trip to \"cancelled\". A message will be send ot the transporter cancelling the trip.": "You have changed the status of the trip to \"cancelled\". A message will be send ot the transporter cancelling the trip.",
        "You have changed the transporter of the trip. A message will be send ot the original transporter cancelling the trip.": "You have changed the transporter of the trip. A message will be send ot the original transporter cancelling the trip.",
        "You have enabled two factor authentication.": "You have enabled two factor authentication.",
        "You have not enabled two factor authentication.": "You have not enabled two factor authentication.",
        "You may accept this invitation by clicking the button below:": "You may accept this invitation by clicking the button below:",
        "You may delete any of your existing tokens if they are no longer needed.": "You may delete any of your existing tokens if they are no longer needed.",
        "You may not delete your personal team.": "You may not delete your personal team.",
        "You may not leave a team that you created.": "You may not leave a team that you created.",
        "Your account": "Your account",
        "Your account for {siteName}": "Your account for {siteName}",
        "Your account is inactive. Please contact support.": "Your account is inactive. Please contact support.",
        "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.": "Your account is inactive. Please contact support. To use the website as a guest, please refresh the page.",
        "Your account is not active. Please contact support.": "Your account is not active. Please contact support.",
        "Your current locale is {locale}": "Your current locale is {locale}",
        "Your email address is unverified.": "Your email address is unverified.",
        "Your last login was {relativeTime} on {dateTime}": "Your last login was {relativeTime} on {dateTime}",
        "Your new pincode is displayed nonce, at the top of the screen": "Your new pincode is displayed nonce, at the top of the screen",
        "Your new pincode is displayed only once, at the top of the screen": "Your new pincode is displayed only once, at the top of the screen",
        "Your new pincode is: {pincode}": "Your new pincode is: {pincode}",
        "Your Order": "Your Order",
        "Your order": "Your order",
        "Your order was created.": "Your order was created.",
        "Your pincode reset request": "Your pincode reset request",
        "Your Profile": "Your Profile",
        "Your shopping cart is empty": "Your shopping cart is empty",
        "Youtube link": "Youtube link",
        "validation": {
            "accepted": "The {attribute} field must be accepted.",
            "accepted_if": "The {attribute} field must be accepted when {other} is {value}.",
            "active_url": "The {attribute} field must be a valid URL.",
            "after": "The {attribute} field must be a date after {date}.",
            "after_or_equal": "The {attribute} field must be a date after or equal to {date}.",
            "alpha": "The {attribute} field must only contain letters.",
            "alpha_dash": "The {attribute} field must only contain letters, numbers, dashes, and underscores.",
            "alpha_num": "The {attribute} field must only contain letters and numbers.",
            "array": "The {attribute} field must be an array.",
            "ascii": "The {attribute} field must only contain single-byte alphanumeric characters and symbols.",
            "before": "The {attribute} field must be a date before {date}.",
            "before_or_equal": "The {attribute} field must be a date before or equal to {date}.",
            "between": {
                "array": "The {attribute} field must have between {min} and {max} items.",
                "file": "The {attribute} field must be between {min} and {max} kilobytes.",
                "numeric": "The {attribute} field must be between {min} and {max}.",
                "string": "The {attribute} field must be between {min} and {max} characters."
            },
            "boolean": "The {attribute} field must be true or false.",
            "can": "The {attribute} field contains an unauthorized value.",
            "confirmed": "The {attribute} field confirmation does not match.",
            "contains": "The {attribute} field is missing a required value.",
            "current_password": "The password is incorrect.",
            "date": "The {attribute} field must be a valid date.",
            "date_equals": "The {attribute} field must be a date equal to {date}.",
            "date_format": "The {attribute} field must match the format {format}.",
            "decimal": "The {attribute} field must have {decimal} decimal places.",
            "declined": "The {attribute} field must be declined.",
            "declined_if": "The {attribute} field must be declined when {other} is {value}.",
            "different": "The {attribute} field and {other} must be different.",
            "digits": "The {attribute} field must be {digits} digits.",
            "digits_between": "The {attribute} field must be between {min} and {max} digits.",
            "dimensions": "The {attribute} field has invalid image dimensions.",
            "distinct": "The {attribute} field has a duplicate value.",
            "doesnt_end_with": "The {attribute} field must not end with one of the following: {values}.",
            "doesnt_start_with": "The {attribute} field must not start with one of the following: {values}.",
            "email": "The {attribute} field must be a valid email address.",
            "ends_with": "The {attribute} field must end with one of the following: {values}.",
            "enum": "The selected {attribute} is invalid.",
            "exists": "The selected {attribute} is invalid.",
            "extensions": "The {attribute} field must have one of the following extensions: {values}.",
            "file": "The {attribute} field must be a file.",
            "filled": "The {attribute} field must have a value.",
            "gt": {
                "array": "The {attribute} field must have more than {value} items.",
                "file": "The {attribute} field must be greater than {value} kilobytes.",
                "numeric": "The {attribute} field must be greater than {value}.",
                "string": "The {attribute} field must be greater than {value} characters."
            },
            "gte": {
                "array": "The {attribute} field must have {value} items or more.",
                "file": "The {attribute} field must be greater than or equal to {value} kilobytes.",
                "numeric": "The {attribute} field must be greater than or equal to {value}.",
                "string": "The {attribute} field must be greater than or equal to {value} characters."
            },
            "hex_color": "The {attribute} field must be a valid hexadecimal color.",
            "image": "The {attribute} field must be an image.",
            "in": "The selected {attribute} is invalid.",
            "in_array": "The {attribute} field must exist in {other}.",
            "integer": "The {attribute} field must be an integer.",
            "ip": "The {attribute} field must be a valid IP address.",
            "ipv4": "The {attribute} field must be a valid IPv4 address.",
            "ipv6": "The {attribute} field must be a valid IPv6 address.",
            "json": "The {attribute} field must be a valid JSON string.",
            "list": "The {attribute} field must be a list.",
            "lowercase": "The {attribute} field must be lowercase.",
            "lt": {
                "array": "The {attribute} field must have less than {value} items.",
                "file": "The {attribute} field must be less than {value} kilobytes.",
                "numeric": "The {attribute} field must be less than {value}.",
                "string": "The {attribute} field must be less than {value} characters."
            },
            "lte": {
                "array": "The {attribute} field must not have more than {value} items.",
                "file": "The {attribute} field must be less than or equal to {value} kilobytes.",
                "numeric": "The {attribute} field must be less than or equal to {value}.",
                "string": "The {attribute} field must be less than or equal to {value} characters."
            },
            "mac_address": "The {attribute} field must be a valid MAC address.",
            "max": {
                "array": "The {attribute} field must not have more than {max} items.",
                "file": "The {attribute} field must not be greater than {max} kilobytes.",
                "numeric": "The {attribute} field must not be greater than {max}.",
                "string": "The {attribute} field must not be greater than {max} characters."
            },
            "max_digits": "The {attribute} field must not have more than {max} digits.",
            "mimes": "The {attribute} field must be a file of type: {values}.",
            "mimetypes": "The {attribute} field must be a file of type: {values}.",
            "min": {
                "array": "The {attribute} field must have at least {min} items.",
                "file": "The {attribute} field must be at least {min} kilobytes.",
                "numeric": "The {attribute} field must be at least {min}.",
                "string": "The {attribute} field must be at least {min} characters."
            },
            "min_digits": "The {attribute} field must have at least {min} digits.",
            "missing": "The {attribute} field must be missing.",
            "missing_if": "The {attribute} field must be missing when {other} is {value}.",
            "missing_unless": "The {attribute} field must be missing unless {other} is {value}.",
            "missing_with": "The {attribute} field must be missing when {values} is present.",
            "missing_with_all": "The {attribute} field must be missing when {values} are present.",
            "multiple_of": "The {attribute} field must be a multiple of {value}.",
            "not_in": "The selected {attribute} is invalid.",
            "not_regex": "The {attribute} field format is invalid.",
            "numeric": "The {attribute} field must be a number.",
            "password": {
                "letters": "The {attribute} field must contain at least one letter.",
                "mixed": "The {attribute} field must contain at least one uppercase and one lowercase letter.",
                "numbers": "The {attribute} field must contain at least one number.",
                "symbols": "The {attribute} field must contain at least one symbol.",
                "uncompromised": "The given {attribute} has appeared in a data leak. Please choose a different {attribute}."
            },
            "present": "The {attribute} field must be present.",
            "present_if": "The {attribute} field must be present when {other} is {value}.",
            "present_unless": "The {attribute} field must be present unless {other} is {value}.",
            "present_with": "The {attribute} field must be present when {values} is present.",
            "present_with_all": "The {attribute} field must be present when {values} are present.",
            "prohibited": "The {attribute} field is prohibited.",
            "prohibited_if": "The {attribute} field is prohibited when {other} is {value}.",
            "prohibited_if_accepted": "The {attribute} field is prohibited when {other} is accepted.",
            "prohibited_if_declined": "The {attribute} field is prohibited when {other} is declined.",
            "prohibited_unless": "The {attribute} field is prohibited unless {other} is in {values}.",
            "prohibits": "The {attribute} field prohibits {other} from being present.",
            "regex": "The {attribute} field format is invalid.",
            "required": "The {attribute} field is required.",
            "required_array_keys": "The {attribute} field must contain entries for: {values}.",
            "required_if": "The {attribute} field is required when {other} is {value}.",
            "required_if_accepted": "The {attribute} field is required when {other} is accepted.",
            "required_if_declined": "The {attribute} field is required when {other} is declined.",
            "required_unless": "The {attribute} field is required unless {other} is in {values}.",
            "required_with": "The {attribute} field is required when {values} is present.",
            "required_with_all": "The {attribute} field is required when {values} are present.",
            "required_without": "The {attribute} field is required when {values} is not present.",
            "required_without_all": "The {attribute} field is required when none of {values} are present.",
            "same": "The {attribute} field must match {other}.",
            "size": {
                "array": "The {attribute} field must contain {size} items.",
                "file": "The {attribute} field must be {size} kilobytes.",
                "numeric": "The {attribute} field must be {size}.",
                "string": "The {attribute} field must be {size} characters."
            },
            "starts_with": "The {attribute} field must start with one of the following: {values}.",
            "string": "The {attribute} field must be a string.",
            "timezone": "The {attribute} field must be a valid timezone.",
            "ulid": "The {attribute} field must be a valid ULID.",
            "unique": "The {attribute} has already been taken.",
            "uploaded": "The {attribute} failed to upload.",
            "uppercase": "The {attribute} field must be uppercase.",
            "url": "The {attribute} field must be a valid URL.",
            "uuid": "The {attribute} field must be a valid UUID.",
            "attributes": {
                "address": "address",
                "address_id": "address",
                "affiliate_url": "affiliate URL",
                "age": "age",
                "air_waybill_id": "air waybill",
                "air_waybill_uuid": "air waybill",
                "amount": "amount",
                "announcement": "announcement",
                "area": "area",
                "audience_prize": "audience prize",
                "audience_winner": "audience winner",
                "available": "available",
                "billing_address.city": "city",
                "billing_address.country": "country",
                "billing_address.postcode": "postcode",
                "billing_address.street_name": "street name",
                "billing_address.street_number": "street number",
                "billing_address.street_number_addition": "street number addition",
                "birthday": "birthday",
                "body": "body",
                "city": "city",
                "company": "company",
                "compilation": "compilation",
                "concept": "concept",
                "conditions": "conditions",
                "consultant_id": "consultant",
                "content": "content",
                "contest": "contest",
                "country": "country",
                "cover": "cover",
                "created_at": "created at",
                "creator": "creator",
                "currency": "currency",
                "current_password": "current password",
                "customer": "customer",
                "date": "date",
                "date_of_birth": "date of birth",
                "dates": "dates",
                "day": "day",
                "deleted_at": "deleted at",
                "description": "description",
                "display_type": "display type",
                "district": "district",
                "duration": "duration",
                "email": "email",
                "excerpt": "excerpt",
                "filter": "filter",
                "finished_at": "finished at",
                "first_name": "first name",
                "gender": "gender",
                "grand_prize": "grand prize",
                "group": "group",
                "hour": "hour",
                "image": "image",
                "image_desktop": "desktop image",
                "image_main": "main image",
                "image_mobile": "mobile image",
                "images": "images",
                "is_audience_winner": "is audience winner",
                "is_hidden": "is hidden",
                "is_subscribed": "is subscribed",
                "is_visible": "is visible",
                "is_winner": "is winner",
                "items": "items",
                "key": "key",
                "last_name": "last name",
                "lesson": "lesson",
                "line_address_1": "line address 1",
                "line_address_2": "line address 2",
                "login": "login",
                "message": "message",
                "middle_name": "middle name",
                "minute": "minute",
                "mobile": "mobile",
                "month": "month",
                "name": "name",
                "national_code": "national code",
                "network_id": "network",
                "number": "number",
                "package_id": "package",
                "pallet_id": "pallet",
                "password": "password",
                "password_confirmation": "password confirmation",
                "phone": "phone",
                "photo": "photo",
                "pickup_id": "pickup",
                "portfolio": "portfolio",
                "postal_code": "postal code",
                "preview": "preview",
                "price": "price",
                "product_id": "product ID",
                "product_uid": "product UID",
                "product_uuid": "product UUID",
                "promo_code": "promo code",
                "province": "province",
                "quantity": "quantity",
                "reason": "reason",
                "recaptcha_response_field": "recaptcha response field",
                "referee": "referee",
                "referees": "referees",
                "reject_reason": "reject reason",
                "remember": "remember",
                "restored_at": "restored at",
                "result_text_under_image": "result text under image",
                "role": "role",
                "role_id": "role",
                "rule": "rule",
                "rules": "rules",
                "second": "second",
                "sex": "sex",
                "shipment": "shipment",
                "shipping_address.city": "city",
                "shipping_address.country": "country",
                "shipping_address.postcode": "postcode",
                "shipping_address.street_name": "street name",
                "shipping_address.street_number": "street number",
                "shipping_address.street_number_addition": "street number addition",
                "shipping_rate_id": "shipping rate",
                "short_text": "short text",
                "size": "size",
                "skills": "skills",
                "slug": "slug",
                "specialization": "specialization",
                "started_at": "started at",
                "state": "state",
                "status": "status",
                "street": "street",
                "student": "student",
                "subject": "subject",
                "tag": "tag",
                "tags": "tags",
                "teacher": "teacher",
                "terms": "terms",
                "test_description": "test description",
                "test_locale": "test locale",
                "test_name": "test name",
                "text": "text",
                "time": "time",
                "title": "title",
                "type": "type",
                "updated_at": "updated at",
                "user": "user",
                "username": "username",
                "value": "value",
                "winner": "winner",
                "work": "work",
                "year": "year"
            }
        },
        "passwords": {
            "reset": "Your password has been reset.",
            "sent": "We have emailed your password reset link.",
            "throttled": "Please wait before retrying.",
            "token": "This password reset token is invalid.",
            "user": "We can't find a user with that email address."
        },
        "auth": {
            "failed": "These credentials do not match our records.",
            "password": "The provided password is incorrect.",
            "throttle": "Too many login attempts. Please try again in {seconds} seconds.",
            "throttle_generic": "Too many attempts. Please try again in {seconds} seconds."
        },
        "routes": {
            "products": "products",
            "blog": "blog",
            "about-us": "about-us",
            "general-terms": "general-terms",
            "privacy-policy": "privacy-policy",
            "cookie-policy": "cookie-policy",
            "contact": "contact"
        },
        "http-statuses": {
            "0": "Unknown Error",
            "100": "Continue",
            "101": "Switching Protocols",
            "102": "Processing",
            "200": "OK",
            "201": "Created",
            "202": "Accepted",
            "203": "Non-Authoritative Information",
            "204": "No Content",
            "205": "Reset Content",
            "206": "Partial Content",
            "207": "Multi-Status",
            "208": "Already Reported",
            "226": "IM Used",
            "300": "Multiple Choices",
            "301": "Moved Permanently",
            "302": "Found",
            "303": "See Other",
            "304": "Not Modified",
            "305": "Use Proxy",
            "307": "Temporary Redirect",
            "308": "Permanent Redirect",
            "400": "Bad Request",
            "401": "Unauthorized",
            "402": "Payment Required",
            "403": "Forbidden",
            "404": "Not Found",
            "405": "Method Not Allowed",
            "406": "Not Acceptable",
            "407": "Proxy Authentication Required",
            "408": "Request Timeout",
            "409": "Conflict",
            "410": "Gone",
            "411": "Length Required",
            "412": "Precondition Failed",
            "413": "Payload Too Large",
            "414": "URI Too Long",
            "415": "Unsupported Media Type",
            "416": "Range Not Satisfiable",
            "417": "Expectation Failed",
            "418": "I'm a teapot",
            "419": "Session Has Expired",
            "421": "Misdirected Request",
            "422": "Unprocessable Entity",
            "423": "Locked",
            "424": "Failed Dependency",
            "425": "Too Early",
            "426": "Upgrade Required",
            "428": "Precondition Required",
            "429": "Too Many Requests",
            "431": "Request Header Fields Too Large",
            "444": "Connection Closed Without Response",
            "449": "Retry With",
            "451": "Unavailable For Legal Reasons",
            "499": "Client Closed Request",
            "500": "Internal Server Error",
            "501": "Not Implemented",
            "502": "Bad Gateway",
            "503": "Maintenance Mode",
            "504": "Gateway Timeout",
            "505": "HTTP Version Not Supported",
            "506": "Variant Also Negotiates",
            "507": "Insufficient Storage",
            "508": "Loop Detected",
            "509": "Bandwidth Limit Exceeded",
            "510": "Not Extended",
            "511": "Network Authentication Required",
            "520": "Unknown Error",
            "521": "Web Server is Down",
            "522": "Connection Timed Out",
            "523": "Origin Is Unreachable",
            "524": "A Timeout Occurred",
            "525": "SSL Handshake Failed",
            "526": "Invalid SSL Certificate",
            "527": "Railgun Error",
            "598": "Network Read Timeout Error",
            "599": "Network Connect Timeout Error",
            "unknownError": "Unknown Error"
        },
        "pagination": {
            "next": "Next &raquo;",
            "previous": "&laquo; Previous"
        },
        "actions": {
            "accept": "Accept",
            "action": "Action",
            "actions": "Actions",
            "add": "Add",
            "admin": "Admin",
            "agree": "Agree",
            "archive": "Archive",
            "assign": "Assign",
            "associate": "Associate",
            "attach": "Attach",
            "browse": "Browse",
            "cancel": "Cancel",
            "choose": "Choose",
            "choose_file": "Choose File",
            "choose_image": "Choose Image",
            "click_to_copy": "Click to copy",
            "close": "Close",
            "collapse": "Collapse",
            "collapse_all": "Collapse All",
            "comment": "Comment",
            "confirm": "Confirm",
            "connect": "Connect",
            "create": "Create",
            "delete": "Delete",
            "detach": "Detach",
            "details": "Details",
            "disable": "Disable",
            "discard": "Discard",
            "done": "Done",
            "down": "Down",
            "duplicate": "Duplicate",
            "edit": "Edit",
            "enable": "Enable",
            "expand": "Expand",
            "expand_all": "Expand All",
            "explanation": "Explanation",
            "export": "Export",
            "file": "File",
            "files": "Files",
            "go_home": "Go Home",
            "hide": "Hide",
            "home": "Home",
            "image": "Image",
            "impersonate": "Impersonate",
            "impersonation": "Impersonation",
            "import": "Import",
            "introduction": "Introduction",
            "like": "Like",
            "load": "Load",
            "localize": "Localize",
            "log_in": "Log In",
            "log_out": "Log Out",
            "named": {
                "add": "Add {name}",
                "choose": "Choose {name}",
                "create": "Create {name}",
                "delete": "Delete {name}",
                "duplicate": "Duplicate {name}",
                "edit": "Edit {name}",
                "export": "Export {name}",
                "hide": "Hide {name}",
                "import": "Import {name}",
                "new": "New {name}",
                "restore": "Restore {name}",
                "save": "Save {name}",
                "search": "Search {name}",
                "show": "Show {name}",
                "update": "Update {name}",
                "view": "View {name}"
            },
            "new": "New",
            "no": "No",
            "open": "Open",
            "open_website": "Open on the website",
            "preview": "Preview",
            "price": "Price",
            "record": "Record",
            "restore": "Restore",
            "save": "Save",
            "save_and_close": "Save & Close",
            "save_and_return": "Save & Return",
            "search": "Search",
            "select": "Select",
            "select_all": "Select All",
            "send": "Send",
            "settings": "Settings",
            "show": "Show",
            "show_all": "Show All",
            "sign_in": "Sign In",
            "solve": "Solve",
            "start": "Start",
            "stop": "Stop",
            "submit": "Submit",
            "subscribe": "Subscribe",
            "switch": "Switch",
            "switch_to_role": "Switch To Role",
            "tag": "Tag",
            "tags": "Tags",
            "target_link": {
                "blank": "Open in a new window",
                "parent": "Open in a parent frame",
                "self": "Open in a current window",
                "top": "Open in the topmost frame"
            },
            "translate": "Translate",
            "translate_it": "Translate It",
            "unpack": "Unpack",
            "unsubscribe": "Unsubscribe",
            "up": "Up",
            "update": "Update",
            "user": "User",
            "view": "View",
            "yes": "Yes"
        }
    }
}
